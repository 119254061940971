.error-container {
  width: 100%;
  height: 100%;
  display: flex;

  .error-left {
    display: inline-flex;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .error-contant-left {
      display: inline-flex;
      flex-wrap: wrap;
      width: 80%;
      h1 {
        font-size: 70px;
        line-height: 76px;
        font-weight: 700;
        color: #383874;
        margin: 0;
      }
      h3 {
        font-size: 35px;
        line-height: 40px;
        font-weight: 600;
        color: #383874;
        margin: 20px 0;
      }
      p {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: #383874;
        margin: 0;
      }
    }
  }
  .error-right {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    padding: 0 50px;
  }
  .error-contant-right {
    display: inline-flex;
    flex-wrap: wrap;
    width: 80%;
  }
}
