.discovered-assets-container {
  width: 100%;
  display: block;
  padding: 30px 40px;
  background-color: #f6f7fb;
  .assets-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .global-services-fliter {
    display: block;
    width: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    margin-top: 20px;
    padding: 15px 15px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
    .heading {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: #383874;
      span {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #1b4dff;
      }
    }
  }
  .search-box {
    margin-top: 20px;
    .search {
      display: inline-flex;
      width: 60%;
      height: 36px;
      background-color: #eef0fa;
      border-radius: 7px;
      margin-right: 15px;

      input {
        display: inline-flex;
        width: 100%;
        border: none;
        background-color: transparent;
        padding-left: 15px;
        padding-right: 15px;
        color: #000000;
        font-size: 13px;
        line-height: 36px;
      }

      button {
        display: inline-flex;
        border: none;
        cursor: pointer;
        background-color: transparent;
        color: rgb(171, 173, 216);
        padding: 6px 7px 0;
      }
    }
    .undo-icon {
      color: #a1a3d4;
    }

    .primary-btn {
      padding-left: 15px;
      padding-right: 15px;

      a {
        color: #ffffff;
      }
    }
  }
}
.tag-status {
  position: relative;
  cursor: pointer;

  .fliter-toggel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background: #dde1f8;
    color: #383874;
    font-size: 12px;
    font-weight: 600;
    height: 36px;
    padding: 0 15px;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .arrow-icon {
      margin-left: 20px;
    }
  }

  .fliter-collapse {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 3px;
    display: none;
    position: absolute;
    top: 38px;
    width: 172px;
    right: 0;
    z-index: 10;
    overflow: hidden;
    overflow-y: auto;
    max-height: 200px;
    box-shadow: 0px 4px 32px 0px #00103d29;

    &.active {
      display: block;
    }

    ul {
      display: block;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
        margin-bottom: 0;
        color: #383874;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        padding: 7px 10px;
        border-radius: 3px;
        cursor: pointer;

        i {
          margin-right: 7px;
          color: #00b929;
        }
      }
    }
  }

  .fliters-collapse-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    display: none;

    &.active {
      display: block;
    }
  }
}
