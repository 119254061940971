*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #f6f7fb;
  font-family: "Poppins", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d9da;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c1c2c2;
}

ul,
li {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 8px;
}

.main-view {
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 70px;
  padding-top: 50px;
}

.standalone-container {
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 70px;
  padding-top: 50px;
  transition: width 0.2s;

  &:has(.open) {
    padding-left: 200px;
    transition: width 0.2s;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

input:focus-visible {
  outline: none;
}

.custom-scrollbar--page {
  overflow: inherit !important;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.min-width-inherit {
  min-width: inherit !important;
}

.border-bottom-0 {
  border-bottom: none !important;
}

.border-bottom {
  border-bottom: 1px solid #d2cbcb;
}

.border-top {
  border-top: 1px solid #d2cbcb;
}

.border-right {
  border-right: 1px solid #d2cbcb;
}

.border-left {
  border-left: 1px solid #d2cbcb;
}

button {
  &:focus {
    outline: none;
  }
}

.btn-link {
  color: #3b4859;

  i {
    margin-right: 8px;
    font-size: 18px;
    color: #57a4ff;
  }
}

h3 {
  font-size: 20px;
  line-height: 24px;
  color: #3b4859;
  font-weight: 500;
  margin-bottom: 10px;
}

h4 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000000;
  margin: 15px 0;
}

h5 {
  font-size: 16px;
  line-height: 20px;
  color: #3b4859;
  font-weight: 500;
  margin: 0;
}

p {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
  // margin-bottom: 0;
}

//*********************************** Password Must Include *********************************//
.password-must-include-contents {
  display: block;
  width: 80%;
  position: relative;
  margin-bottom: 15px;

  label {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #000000;
  }

  ul {
    display: block;
    width: 100%;
    list-style: none;

    li {
      display: flex;
      color: #959595;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 10px;

      span {
        display: inline-block;
        vertical-align: top;
        width: 16px;
        height: 16px;
        font-size: 9px;
        line-height: 16px;
        margin-right: 7px;
        text-align: center;
        border-radius: 50%;
        background-color: rgba(150, 150, 150, 0.5);
      }

      &.green {
        color: #00b929;

        span {
          background-color: rgba(0, 185, 41, 0.3);
        }
      }
    }
  }
}

//*********************************** Common Container *********************************//
.common-container {
  padding-top: 15px;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f5f6f5;
}

.min-width {
  min-width: inherit !important;
}

//*********************************** Buttons *********************************//

button.primary-outline-btn {
  color: #384cff;
  border-color: #384cff;
  border-radius: 5px;
  min-width: 180px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0;
  box-shadow: none;
  text-transform: none;
  font-family: "Open Sans", sans-serif;

  &:hover {
    color: #384cff;
  }
}

a.primary-outline-btn {
  color: #384cff;
  border-width: 1px;
  border-style: solid;
  border-color: #384cff;
  background-color: transparent;
  border-radius: 5px;
  min-width: 180px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0;
  box-shadow: none;
  text-transform: none;
  font-family: "Open Sans", sans-serif;

  &:hover {
    color: #384cff;
    box-shadow: none;
    background-color: transparent;
  }
}

button.primary-btn {
  background: #384cff;
  color: #ffffff;
  min-width: 180px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  box-shadow: none;
  text-transform: none;
  letter-spacing: 0px;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #6b8cff;
    color: #ffffff;
    box-shadow: none;
  }
}

a.primary-btn {
  background: #384cff;
  color: #ffffff;
  min-width: 180px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  box-shadow: none;
  text-transform: none;
  letter-spacing: 0px;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #6b8cff;
    color: #ffffff;
    box-shadow: none;
  }
}

button.primary-text-btn {
  background: #ffffff;
  color: #384cff;
  min-width: 180px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-transform: none;
  letter-spacing: 0px;
  border-radius: 5px;
  box-shadow: none;
  text-transform: none;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #ffffff;
    color: #384cff;
    box-shadow: none;
  }
}

a.primary-text-btn {
  background: #ffffff;
  color: #384cff;
  min-width: 180px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-transform: none;
  letter-spacing: 0px;
  border-radius: 5px;
  box-shadow: none;
  text-transform: none;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #ffffff;
    color: #384cff;
    box-shadow: none;
  }
}

button.secondary-btn {
  background: #dde4e9;
  color: #8392a7;
  border-radius: 5px;
  min-width: 80px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0px;
  box-shadow: none;
  text-transform: none;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #dbe0e4;
    color: #8392a7;
    box-shadow: none;
  }
}

button.secondary-text-btn {
  background: #ffffff;
  color: #8392a7;
  border-color: #e1e1e1;
  display: inline-block;
  border-radius: 5px;
  min-width: 80px;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0;
  box-shadow: none;
  text-transform: none;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #ffffff;
    border-color: #e1e1e1;
    color: #8392a7;
    box-shadow: none;
  }
}

a.secondary-text-btn {
  background: #ffffff;
  color: #9a9ab0;
  border-color: #e1e1e1;
  border-radius: 5px;
  min-width: 80px;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  letter-spacing: 0;
  box-shadow: none;
  text-transform: none;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #ffffff;
    border-color: #e1e1e1;
    color: #9a9ab0;
    box-shadow: none;
  }
}

button.light-btn {
  background: #dde1f8;
  color: #383874;
  border-radius: 5px;
  min-width: 80px;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0;
  box-shadow: none;
  text-transform: none;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #dde1f8;
    color: #383874;
    box-shadow: none;
  }
}

button.danger-btn {
  background-color: #ff2d2e;
  border-color: #ff2d2e;
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  box-shadow: none;
  text-transform: none;
  letter-spacing: 0px;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #ff2d2db6;
    color: #ffffff;
    box-shadow: none;
    border-color: #ff2d2e;
  }
}
button.success-btn {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  box-shadow: none;
  text-transform: none;
  letter-spacing: 0px;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #44a147;
    color: #ffffff;
    box-shadow: none;
    border-color: #44a147;
  }
}
button.pending-btn {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  box-shadow: none;
  text-transform: none;
  letter-spacing: 0px;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #ebb206;
    color: #ffffff;
    box-shadow: none;
    border-color: #ebb206;
  }
}

button.danger-outline-btn {
  border-color: #ff2d2e;
  color: #ff2d2e;
  min-width: 180px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  box-shadow: none;
  text-transform: none;
  letter-spacing: 0px;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #ff2d2db6;
    color: #ffffff;
    box-shadow: none;
    border-color: #ff2d2e;
  }
}

button.info-btn {
  background: #8676ff;
  color: #ffffff;
  min-width: 180px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  box-shadow: none;
  text-transform: none;
  letter-spacing: 0px;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background: #8676ffb7;
    color: #ffffff;
    box-shadow: none;
  }
}

.open-create-menu-close {
  background-color: transparent;
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.asset-disabled {
  cursor: no-drop;
  opacity: 0.8;
}

.sign-in-btn {
  background: #384cff !important;
  text-transform: inherit !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.v-a-top {
  vertical-align: top;
}

.v-a-middle {
  vertical-align: middle;
}

a {
  text-decoration: none;

  &:focus,
  &:hover {
    color: #000000;
  }
}

//*******************************************Loader****************************************//
.loader {
  font-size: 14px;

  span {
    margin-left: 5px;
  }

  &.small-loader {
    font-size: 12px;
  }
}

//*******************************************Pading-top****************************************//

.p-t-0 {
  padding-top: 0 !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

//*******************************************Pading-right****************************************//
.p-r-0 {
  padding-right: 0 !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

//*******************************************Pading-bottom****************************************//
.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

//*******************************************Pading-left****************************************//
.p-l-0 {
  padding-left: 0 !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-0 {
  padding: 0 !important;
}

//*******************************************margin-top****************************************//
.m-l-0 {
  margin-left: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-1 {
  margin-top: 5px !important;
}

.m-t-2 {
  margin-top: 10px !important;
}

.m-t-3 {
  margin-top: 15px !important;
}

.m-t-4 {
  margin-top: 20px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

//*******************************************margin-right****************************************//
.m-r-1 {
  margin-right: 5px !important;
}

.m-r-2 {
  margin-right: 10px !important;
}

.m-r-3 {
  margin-right: 15px !important;
}

.m-r-4 {
  margin-right: 20px !important;
}

.m-l-auto {
  margin-left: auto !important;
}

.m-r-auto {
  margin-right: auto !important;
}

.orange {
  color: #ff8f00;
}

.yellow-green {
  color: #7ed321;
}

.red {
  color: #f93d3d;
}

.yellow {
  color: #ffff00;
}

.blue {
  color: #438afb;
}

//*******************************************width-top****************************************//
.width-25 {
  width: 25% !important;
}

.width-50 {
  width: 50% !important;
}

.width-75 {
  width: 75% !important;
}

.width-100 {
  width: 100% !important;
}

.width-auto {
  width: auto !important;
}

.fliters-container {
  position: relative;

  .select-fliters {
    background: #ffffff;
    border: 1px solid #8392a7;
    border-radius: 4px;
    width: 100%;
    display: block;
    min-height: 42px;
    padding: 5px 15px 0;
    position: relative;

    .fliter-toggel {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    p {
      display: inline-block;
      font-size: 14px;
      line-height: 30px;
      font-weight: 400;
      color: #8998ac;
      opacity: 0.72;
      margin-bottom: 0;
      margin-right: 10px;
      vertical-align: top;
    }

    .add-fliters {
      background-color: #f0f3f7;
      font-size: 12px;
      font-weight: 400;
      color: #414d55;
      line-height: 30px;
      cursor: pointer;
      padding: 0px;
      display: inline-block;
      min-width: 30px;
      margin-right: 5px;
      margin-bottom: 5px;
      vertical-align: top;
      cursor: pointer;
      position: relative;
      z-index: 1;
      text-align: center;

      i {
        float: none;
      }

      &:hover {
        color: #464c54;
      }
    }

    .fliter-selected {
      background-color: #f0f3f7;
      font-size: 12px;
      font-weight: 400;
      color: #414d55;
      line-height: 30px;
      padding: 0px;
      display: inline-block;
      min-width: 100px;
      margin-right: 5px;
      margin-bottom: 5px;
      vertical-align: top;
      cursor: pointer;
      position: relative;
      z-index: 1;

      span {
        display: block;
        padding: 0 30px 0 10px;
      }

      i {
        font-weight: 100;
        padding-left: 0;
        float: none;
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 1;
      }
    }

    i {
      float: right;
      font-size: 14px;
      line-height: 30px;
      color: #8998ac;
      opacity: 0.72;
      cursor: pointer;
    }
  }

  .fliters-collapse {
    background: #ffffff;
    border: 1px solid #8392a7;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    padding: 15px;
    overflow: hidden;
    overflow-x: auto;
    display: none;
    z-index: 10;

    &.active {
      display: flex;
    }

    .fliters {
      display: flex;
      vertical-align: top;
      padding-left: 7px;
      padding-right: 7px;
      width: 200px;
      flex: 0 0 200px;

      &:first-child {
        padding-left: 0px;
      }

      &:last-child {
        padding-right: 0px;
      }

      .fliter-box {
        border: 1px solid #8392a7;
        border-radius: 3px;

        .heading {
          display: block;
          text-align: center;
          background-color: #eff2f7;
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 5px;
          padding-bottom: 5px;
          font-size: 13px;
          line-height: 18px;
          font-weight: 500;
        }

        .search-control {
          position: relative;
          margin-top: 5px;
          margin-left: 10px;
          margin-right: 10px;

          .btn {
            position: absolute;
            top: 0;
            left: 0;
            width: 15px;
            height: 24px;
            background-color: transparent;
            z-index: 1;
            color: #8392a7;
            font-size: 11px;
            padding: 0;
            font-weight: 100;

            &.btn-clear {
              right: 0;
              left: auto;
              width: auto;
            }
          }

          .input-group-text {
            background-color: #ffffff;
            border: none;
            border-bottom: 1px solid #8392a7;
            width: 100%;
            padding: 0 20px 0 20px;
            height: 24px;
            text-align: left;
            border-radius: 0;
            font-size: 11px;

            &:focus-visible {
              outline: none;
            }

            &:focus {
              outline: none;
            }
          }
        }

        .fliters-links {
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          max-height: 105px;
          overflow: hidden;
          overflow-y: auto;

          .form-check {
            margin-bottom: 5px;
            position: relative;

            input {
              display: inline-block;
              vertical-align: top;
              margin-top: 3px;
              cursor: pointer;
            }

            label {
              display: inline-block;
              vertical-align: top;
              padding-left: 5px;
              font-size: 12px;
              color: #414d55;
              line-height: 20px;
              margin-bottom: 0;
              cursor: pointer;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 140px;
            }
          }
        }
      }
    }
  }

  .fliters-collapse-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    display: none;

    &.active {
      display: block;
    }
  }
}

//*******************************************Pagination Table****************************************//

.access-control-table {
  border-radius: 7px !important;
  box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px #6c49ac0d !important;

  table {
    th {
      font-weight: 500;
      color: #000000;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 14px;
      font-family: "Poppins", sans-serif;
      border-bottom-color: #b3b3b3;
      border-bottom-style: dotted;
      .check-box{
        padding: 5px;
      }
    }

    tbody {
      tr {
        &:last-child {
          td {
            .action-buttons {
              top: auto;
              bottom: 36px;
              height: auto;
            }
          }
        }

        td {
          font-weight: 400;
          color: #383874;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 5px;
          padding-bottom: 5px;
          font-size: 12px;
          line-height: 18px;
          font-family: "Poppins", sans-serif;
          border-bottom-color: #eaecf0;
          border-bottom-style: solid;
          position: relative;

          strong {
            font-weight: 500;
          }
          .role-description {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 250px;
          }
         

          .roles-box {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            background: #00b929;
            display: inline-block;
            padding: 4px 10px;
            border-radius: 4px;
            margin-left: 10px;
            color: #ffffff;

            span {
              margin-right: 5px;
            }
          }

          .group-name {
            display: inline-block;
            border: 1px solid #c6cbd9;
            border-radius: 5px;
            color: #383874;
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            position: relative;
            padding: 5px 10px 5px 20px;

            &::before {
              position: absolute;
              left: 10px;
              top: 10px;
              width: 5px;
              height: 5px;
              content: "";
              border-radius: 50px;
              background-color: #8676ff;
            }
          }

          a {
            color: #384cff;
            font-weight: 600;
          }

          .action-btn {
            padding: 5px 5px;
            border-radius: 5px;
            color: #384cff;

            &:hover {
              background-color: #d1d5ff;
            }
          }

          .action-buttons-bg {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: fixed;
            z-index: 1;
            background-color: transparent;
          }

          .action-buttons {
            position: absolute;
            z-index: 10;
            right: 0;
            top: 50px;
            width: 120px;
            overflow: hidden;
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px
              #6c49ac0d;

            .secondary-text-btn {
              font-size: 12px;
              padding: 5px 10px;
              min-width: 100%;
              text-align: left;
              border-radius: 0;
              color: #383874;
              border-bottom: 1px solid #dbdff1;

              &:last-child {
                border-bottom: none;
              }

              .MuiButton-startIcon {
                font-size: 12px;
                margin-left: 0;
                line-height: 16px;
                vertical-align: middle;
                margin-right: 5px;
                color: #383874;
              }
            }
          }

          .status-btn {
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;

            display: inline-block;
            padding: 4px 10px;
            border-radius: 3px;
            color: #ffffff;
            background: #00b929;
            text-transform: lowercase;
            border: none;

            &.dark {
              background: #656575;
            }

            &::first-letter {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.access-control-pagination {
  p {
    color: #000000;
  }
}

.Toastify__close-button {
  position: absolute;
  top: 23px;
  right: 15px;
}

.accordion-table {
  .table {
    th {
      font-weight: 500;
      color: #000000;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 14px;
      font-family: "Poppins", sans-serif;
      border-bottom-color: #b3b3b3;
      border-bottom-style: dotted;
    }
  }
}

.list-heading {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: #383874;
    margin-bottom: 0;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 30%;
  }

  .breadcrumbs {
    display: inline-flex;
    width: auto;
    margin-left: auto;

    ul {
      list-style: none;
      display: flex;

      li {
        display: inline-flex;
        padding-left: 3px;
        padding-right: 3px;
        line-height: 32px;
        align-items: center;
        font-size: 12px;
        color: #8676ff;
        font-weight: 400;
        cursor: pointer;
        &.active {
          font-weight: 600;
          cursor: auto;
          color: #414d55;
        }

        a {
          color: #8676ff;
        }

        i {
          font-size: 10px;
          color: #414d55;
        }
      }
    }
  }

  .primary-btn {
    margin-left: auto;
  }
}



.dropdown-select-menu {
  display: block;
  width: 100%;
  .select-menu {
    font-size: 14px;
    line-height: 18px;
    color: #383874;
    font-weight: 500;
    span {
      padding: 0;
      margin-right: 8px;
    }
  }
}
.common-list-menu{
  display: block;
  max-width: 180px;
  min-width: 100px;
  ul {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      display: block;
      list-style: none;
      text-align: left;
      border-radius: 3px;
      padding: 5px 10px;
      font-size: 10px;
      line-height: 14px;
      font-weight: 600;
      color: #383874;
      cursor: pointer;
      font-family: 'Poppins';
      i{
        margin-right: 5px;
      }
      &:hover{
        background: #DDE1F8;
      }
    }
  }
}
