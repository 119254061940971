.assetmanager-modal-container {
  .modal-content {
    .heading {
      p {
        span {
          color: #0f5efd;
        }
      }
    }

    .modal-body {
      padding: 0px 10px;
      color: #3b4859;

      .modal-submit-button {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }
}

.select-account-modal-container {
  max-width: 600px !important;
  width: 600px !important;
  -webkit-box-shadow: 0px 0px 16px #3b48593d;
  box-shadow: 0px 0px 16px #3b48593d;
  border-radius: 10px;
  background: white;
  margin: 0 auto;

  .modal-content {
    padding: 15px;

    .modal-header {
      background: white;
      box-shadow: none;
      border-color: #d2cbcb;
      margin-bottom: 10px;
      padding: 0 10px 15px;
      position: relative;
      padding-left: -20px;
      padding-left: -20px;
      margin-left: -15px;
      margin-right: -15px;

      .modal-title {
        color: #383874 !important;
        text-align: center !important;
        margin: 0px;
        padding: 0;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .close-btn {
          position: absolute;
          right: 10px;
          top: -5px;
          cursor: pointer;
          opacity: 1;
          border: none;
        }
      }

      // button {
      //   position: absolute;
      //   right: 10px;
      //   top: 0px;
      //   cursor: pointer;
      //   color: #b5b5cf;
      //   opacity: 1;
      //   border: none;
      //   background-color: transparent;

      //   &:hover {
      //     color: #3b4859;
      //   }
      // }
    }

    .modal-body {
      padding: 0px 10px;
      color: #3b4859;
      .cloud-trail-event {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: #3b4859;
      }
      .request-popup-content {
        p {
          padding: 0px 20px 10px 20px;
          color: #000000;
          &.red {
            padding-top: 3px;
            font-weight: 500;
            color: #f93d3d;
          }
        }
        .input-group {
          .label {
            font-size: 13px;
            line-height: 16px;
            color: #344054;
          }
        }
      }
      .filter-pop-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
        i {
          margin-right: 8px;
        }
      }

      &::-webkit-scrollbar {
        width: 16px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 10px;
        border: 6px solid transparent;
        background-clip: content-box;

        &:hover {
          background-color: #a8bbbf;
        }
      }

      h4 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        color: #000000;
      }

      p {
        font-size: 12px;
        line-height: 18px;
        font-weight: 300;
        color: #838b9c;
      }

      input {
        margin: 0;
        accent-color: #384cff;
      }

      label {
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        color: #383874;
        padding-left: 8px;
        padding-top: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .form-group {
        display: block;
        width: 100%;
        position: relative;
        margin-bottom: 15px;

        label {
          text-align: left;
          display: block;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: #000000;
          margin-bottom: 5px;
          padding-left: 0;
        }

        .form-control {
          text-align: left;
          display: block;
          width: 100%;
          height: 32px;
          padding: 2px 10px;
          border: 1px solid #dcdfe5;
          border-radius: 5px;
          font-size: 13px;
          line-height: 26px;
          font-weight: 400;
          color: #344054;
          font-family: "Poppins", sans-serif;

          &::placeholder {
            color: #b5b5cf;
          }
        }

        textarea {
          &.form-control {
            height: 80px;
            padding: 10px;
            color: #344054;
            line-height: 16px;
          }
        }

        .red {
          font-size: 12px;
        }
      }

      .environment-box {
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #b5b5cf;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        cursor: pointer;

        .environment-title {
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          color: #9a9aaf;
        }

        .envir-image {
          background-repeat: no-repeat;
          display: block;
          width: 25px;
          height: 25px;
          margin: 0 auto 10px;
        }

        .department {
          background-image: url("../../img/assetmanager/envir-light1.png");
        }

        .testing {
          background-image: url("../../img/assetmanager/envir-light2.png");
        }

        .stage {
          background-image: url("../../img/assetmanager/envir-light3.png");
        }

        .production {
          background-image: url("../../img/assetmanager/envir-light4.png");
        }

        &.active {
          border: 1px solid #383874;

          .environment-title {
            color: #383874;
            font-weight: 600;
          }

          .department {
            background-image: url("../../img/assetmanager/envir-dark1.png");
          }

          .testing {
            background-image: url("../../img/assetmanager/envir-dark2.png");
          }

          .stage {
            background-image: url("../../img/assetmanager/envir-dark3.png");
          }

          .production {
            background-image: url("../../img/assetmanager/envir-dark4.png");
          }
        }
      }
    }

    .footer-top-bar {
      button.gray-outline {
        padding: 20px 15px 0;
        background: none;
        border: none;
        margin-right: 15px;
      }

      button.primary-btn {
        min-width: 100px;
      }

      button.primary-outline-btn {
        margin-right: 15px;
        min-width: 100px;
      }
    }
  }
  &.select-language-modal {
    max-width: 620px !important;
    .checkbox-group {
      display: flex;
      flex-wrap: wrap;
      .check-box {
        width: 105px;
        margin-bottom: 10px;
        margin-right: 5px;
        label {
          width: 95px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  &.info-modal {
    .overview-contant {
      h5 {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: #000000;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #000000;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: #000000;
        }
      }
    }
  }
  &.deploy-modal {
    max-width: 900px !important;
    .deploy-modal-contant {
      margin-top: 10px;
      .form-group {
        display: block;
        width: 100%;
        position: relative;
        margin-bottom: 15px;

        label {
          display: block;
          margin-bottom: 5px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: #344054;
        }

        .form-control {
          width: 100%;
          height: 32px;
          padding: 2px 10px;
          border: 1px solid #dcdfe5;
          border-radius: 5px;
          font-size: 13px;
          line-height: 26px;
          font-weight: 300;
          color: #344054;

          &::placeholder {
            color: #a0a0b5;
          }
        }

        .red {
          font-size: 12px;
          line-height: 16px;
        }
        .custom-url {
          height: 32px;
          border: none;
          font-size: 13px;
          line-height: 26px;
          font-weight: 300;
          color: #344054;
          border-bottom: 1px solid #344054;
          border-radius: 0;
        }
        p {
          font-size: 12px;
          line-height: 16px;
          color: #344054;
          margin-bottom: 0;
        }
      }
      .checkbox-group {
        .service-check-box {
          span {
            padding: 5px;
            margin-bottom: 0;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            font-family: "Poppins", sans-serif;
          }
        }
      }
    }
  }
  &.appliction-successfully-modal {
    margin: 85px auto;
    .appliction-contant {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
