.product-category-container {
  .product-title-card {
    margin-top: 17px;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 6px;
    h3 {
      margin-bottom: 0;
      color: #000000;
      font-size: 16px;
    }

    button {
      &.primary-btn {
        min-width: auto;
        padding: 6px 18px;
      }
    }
  }

  .product-category-cards {
    margin-top: 4px;
    box-shadow: none;
    min-height: 156px;
    padding: 20px 15px;
    background-color: #ffffff;
    border-radius: 6px;

    .content {
      font-size: 11px;
      line-height: 16px;
      color: #667085;
    }
    .product-category-inner {
      display: -webkit-box;
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
      .product-category-card {
        position: relative;
        width: 135px;
        height: 135px;
        padding: 10px;
        margin: 5px 8px;
        border-radius: 6px;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        // box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px #6c49ac0d;
        .icon-buttons {
          position: absolute;
          top: 5px;
          right: 5px;
          .edit-icon {
            width: 20px;
            height: 20px;
            border-radius: 50px;
            padding: 5px;
            font-size: 12px;
            color: #384cff;
          }
          .close-icon {
            width: 20px;
            height: 20px;
            border-radius: 50px;
            padding: 5px;
            font-size: 12px;
            color: red;
          }
        }
        // &:last-child {
        //   margin-right: 0;
        // }
        .product-category-details {
          text-align: center;
          .product-image {
            width: 35px;
            height: 35px;
            margin: 0 auto;
          }
          .name {
            margin-top: 8px;
            font-size: 15px;
            line-height: 20px;
            color: #344054;
            font-weight: 500;
            max-height: 3.2em;
            display: block;
            width: 115px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &.active {
          border: 1px solid #384cff;
        }
        &.edit-active {
          background: #f2f5fa;
        }
      }
    }
  }
}
