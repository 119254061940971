.environment-container {
  .search-box {
    float: right;
    width: 250px;

    .search-control-group {
      position: relative;

      .input-group-text {
        padding: 5px 30px 5px 12px;
        border: none;
        background-color: #eef0fa;
      }
      ::placeholder {
        color: #8676FF;
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: #8676FF;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
       color: #8676FF;
      }

      .search-btn {
        top: 0px;
        padding: 0;
        right: 0px;
        left: auto;
        border: none;
        color: #A1A3D4;
        position: absolute;
        background: transparent;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .environmentlist-container {
    .search-box {
      width: 100%;
    }
  }
}
