.sign-container {
  width: 100%;
  height: 100%;
  display: flex;

  .sign-step {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .sign-left {
    display: inline-flex;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: #f6f7fb;

    .sign-left-content {
      display: inline-flex;
      flex-wrap: wrap;
      width: 80%;

      span {
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        color: #92929d;
        margin-bottom: 15px;
      }

      h2 {
        font-size: 36px;
        line-height: 40px;
        font-weight: 600;
        color: #11142d;
        margin-top: 0;
        margin-bottom: 0;
      }

      .banner-image {
        margin-top: 50px;
        justify-content: center;
      }
    }
  }

  .sign-right {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 50%;
    height: 100%;
    background: #fff;

    .sign-right-content {
      width: 70%;
      flex-wrap: wrap;
      //display: inline-flex;

      .steps-container {
        display: flex;
        width: 100%;
        padding: 0;
        margin: 0 0 30px 0;
        list-style: none;

        li {
          position: relative;
          display: inline-flex;
          font-size: 0px;
          text-indent: -99990px;
          width: 24px;
          height: 24px;
          padding: 4px;
          margin-left: 75px;
          border-radius: 50%;
          background-color: #ffffff;

          &:first-child {
            margin-left: 0;

            &::after {
              display: none;
            }
          }

          &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 24px;
            height: 24px;
            content: "";
            border-radius: 50%;
            border: 2px solid #5541d7;
          }

          &::after {
            position: absolute;
            left: -75px;
            top: 11px;
            content: "";
            width: 75px;
            height: 2px;
            background-color: #dbd7f4;
          }

          span {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: block;
            background-color: #ffffff;
          }

          &.active {
            span {
              background-color: #5541d7;
            }

            &::after {
              background-color: #5541d7;
            }
          }
        }
      }

      .heading {
        font-size: 24px;
        line-height: 30px;
        font-weight: 400;
        color: #11142d;
        margin-bottom: 20px;
        flex-wrap: wrap;

        strong {
          margin-left: 8px;
        }

        span {
          margin-top: 10px;
          display: block;
          width: 100%;
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: #9999a3;
        }
      }

      .google-btn {
        .primary-btn {
          width: 50%;
        }
      }

      .or-contant {
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 60%;
          width: 100%;
          height: 1px;
          background-color: #e9e9e9;
        }

        span {
          position: relative;
          z-index: 1;
          display: inline-block;
          padding-left: 15px;
          padding-right: 15px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: #92929d;
          margin-top: -15px;
          background-color: #ffffff;
        }
      }

      .input-group {
        display: block;
        width: 100%;
        position: relative;
        margin-bottom: 15px;

        label {
          margin-bottom: 5px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: #344054;
        }


        .form-control {
          width: 100%;
          height: 32px;
          padding: 2px 10px;
          border: 1px solid #dcdfe5;
          border-radius: 5px;
          font-size: 13px;
          line-height: 26px;
          font-weight: 400;
          color: #495057;

          &::placeholder {
            color: #a0a0b5;
          }
        }

        .eye-icon {
          position: absolute;
          top: 33px;
          right: 10px;
          color: #a0a0b5;
          cursor: pointer;
          width: 20px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
          border: none;
        }
        select {
          width: 100%;
          height: 32px;
          padding: 2px 10px;
          border: 1px solid #dcdfe5;
          border-radius: 5px;
          font-size: 13px;
          line-height: 26px;
          font-weight: 400;
          color: #495057;

          &:focus {
            outline: none;
          }
        }

        p {
          font-size: 12px;
          line-height: 16px;
          margin-top: 3px;
          color: red;
        }

        .input-group-text {
          position: absolute;
          right: 32px;
          top: 0;

          &.rotate {
            right: 40px;
            top: 29px;
            i {
              margin-top: 1px;
              color: #8676ff;
              font-size: 14px;
            }
          }
        }
      }

      .remember-content {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .checkbox {
          margin-left: -11px;
          .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{
            color: #384cff;
          }

          .MuiFormControlLabel-label {
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            color: #a0a0b5;
            margin: 0;
            font-family: "Poppins", sans-serif;
          }
        }

        p {
          font-size: 12px;
          line-height: 20px;
          font-weight: 400;
          color: #a0a0b5;
          margin: 0;
          font-family: "Poppins", sans-serif;
        }

        .error-text {
          width: 100%;
          display: block;
          font-size: 12px;
          line-height: 16px;
          margin-top: 3px;
          color: red;
        }

        a {
          margin-left: auto;
          font-size: 13px;
          line-height: 20px;
          font-weight: 500;
          color: #9a9ab0;

          &:hover {
            color: #5541d7;
          }
        }
      }

      .next-step {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0;
          margin-left: auto;
          color: #9a9ab0;
          font-size: 13px;
          line-height: 35px;
          font-weight: 400;

          a {
            font-weight: 600;
            color: #384cff;
            margin-left: 5px;
          }
        }
      }

      .authentication-section {
        display: block;

        .authentication-image {
          margin: 30px 0;
        }

        .next-step {
          display: block;
          text-align: center;
        }
      }

      .select-profile {
        display: flex;
        margin-top: 10px;
        margin-bottom: 30px;

        .profile-image {
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          background: #e2e2ea;
          padding: 10px;

          img {
            max-height: 100%;
          }
        }

        .company-content {
          margin-left: 10px;

          p {
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            color: #11142d;
            margin: 12px 0;
          }

          .sign-in-btn {
            background: #384cff;
            text-transform: inherit;
            min-width: 120px;
          }

          .label {
            .image-upload {
              padding: 8px;
              cursor: pointer;
              background: #384cff;
              color: #ffffff;
              font-weight: 600;
              font-size: 13px;
              line-height: 24px;
              border-radius: 5px;
              font-family: "Open Sans", sans-serif;
            }
          }
        }
      }

      .back-btn {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
          padding-left: 10px;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .sign-container {
    .sign-right {
      .sign-right-content {
        .next-step {
          flex-wrap: wrap;

          p {
            width: 100%;
          }
        }
      }
    }
  }
}