//
// Modals
// --------------------------------------------------

// Background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: rgba(20, 20, 20, 0.7);
}

.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.7;
}

// Base modal
.modal {
  position: fixed;
  z-index: 1060;
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: transparent;
  box-shadow: none;
  outline: none;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0%;
}

.modal-header {
  background: white;
  box-shadow: none;
  border-bottom: 1px solid #c6cbd9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-header-title {
  font-size: 18px;
  float: left;
  padding-top: 8px;
  margin: 0 16px;
}

.modal-header-close {
  float: right;
  padding: 9px 16px;
}

// Body (where all modal content resides)
.modal-body {
  position: relative;
}

.modal-content {
  padding: 16px * 2;

  &--has-scroll {
    max-height: calc(100vh - 400px);
    position: relative;
  }
}

// Remove bottom margin if need be
.modal-form {
  margin-bottom: 0;
}

// Footer (for actions)
.modal-footer {
  padding: 14px 15px 15px;
  border-top: 1px solid #ffffff;
  background-color: #ffffff;
  text-align: right; // right align buttons
}

.modal--narrow {
  max-width: 500px;
}

.confirm-modal {
  max-width: 500px;

  .confirm-modal-icon {
    padding-top: 41px;
    font-size: 280%;
    color: #1a7f4b;
    padding-bottom: 20px;
  }

  .confirm-modal-text {
    font-size: 1.2857142857142858rem;
    color: rgb(204, 204, 220);
    margin-bottom: 16px * 2;
    padding-top: 16px;
  }

  .confirm-modal-text2 {
    font-size: 14px;
    padding-top: 16px;
  }

  .confirm-modal-buttons {
    margin-bottom: 16px;

    button {
      margin-right: calc(16px / 2);
    }
  }

  .modal-content-confirm-text {
    margin-bottom: 32px;

    span {
      text-align: center;
    }
  }
}

.share-modal-body {
  .share-modal-options {
    margin: 11px 0px 33px 0px;
    display: inline-block;
  }

  .share-modal-big-icon {
    margin-right: 8px;
    margin-top: -7px;
  }

  .share-modal-info-text {
    margin-top: 5px;

    strong {
      color: #000000;
      font-weight: 500;
    }
  }

  .share-modal-header {
    display: flex;
    margin: 0px 0 22px 0;
  }

  .share-modal-content {
    flex-grow: 1;
  }

  .share-modal-link {
    max-width: 716px;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }
}
