.reports-container {
  width: 100%;
  height: 100%;
  display: block;
  margin: -50px 0 0 0;
  padding: 0 60px;
  background-color: #f6f7fb;
  position: relative;

  .reports-inner {
    width: 850px;
    height: 850px;
    margin: 0 auto;
    border-radius: 50%;
    padding: 75px;
    background: #f6f7fb;
    border: 20px solid rgba(255, 255, 255, 0.49);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 21.2%;
    top: 0;
    z-index: 0;

    .reports-bg-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 60px;
      border-radius: 50%;
      background: #ffffff;
      overflow: hidden;

      img {
        max-width: 750px;
        margin-top: 65px;
      }
    }
  }

  .page-heading {
    display: block;
    width: 100%;
    position: relative;
    margin-top: 90px;

    h2 {
      margin: 0px;
      font-size: 24px;
      font-weight: 400;
      line-height: 38px;
      color: #383874;
    }
  }

  .reports-service-box {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;

    .service-box {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border-radius: 5px;
      box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07),
        0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198),
        0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275),
        0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035),
        0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725),
        0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);

      .image {
        width: 150px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      .contents {
        width: calc(100% - 150px);
        display: inline-flex;
        margin-left: 0px;
        flex-wrap: wrap;

        span {
          display: flex;
          width: 100%;
          font-size: 14px;
          color: #383874;
        }

        strong {
          display: flex;
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          color: #383874;
        }
      }
    }
  }

  .reports-charts {
    display: block;
    width: 100%;
    position: relative;
    z-index: 1;

    .chart-box {
      display: block;
      width: 100%;
      padding: 20px;
      margin-bottom: 10px;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07),
        0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198),
        0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275),
        0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035),
        0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725),
        0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);

      .heading {
        display: block;
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        color: #383874;

        p {
          margin: 3px 0 0 0;
          font-size: 12px;
          color: #383874;
        }
      }

      .cost-accounts-boxes {
        ul {
          border-bottom: 1px dotted #f7f7f7;
          padding-bottom: 5px;
          margin-bottom: 20px;
          margin-top: 10px;

          li {
            display: inline-block;
            list-style: none;
            font-size: 12px;
            line-height: 16px;
            color: #1e2129;
            font-weight: 400;
            margin-left: 5px;
            margin-right: 5px;

            span {
              display: inline-block;
              vertical-align: middle;
              margin-top: -2px;
              width: 12px;
              height: 12px;
              border-radius: 5px;
              border: 1px solid #f6f7fb;
            }
          }
        }
      }

      .cloud-department-chart {
        padding: 20px 40px;
      }

      &.department-chart {
        padding-top: 30px;
        padding-bottom: 40px;

        .heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 30px;
          position: relative;

          span {
            font-size: 22px;
            line-height: 26px;
            font-weight: 500;
          }

          .chart-fliter {
            display: inline-block;
            width: auto;
            position: relative;

            .fliter-toggel {
              width: auto;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: none;
              background: #dde1f8;
              color: #383874;
              font-size: 12px;
              font-weight: 600;
              height: 36px;
              padding: 0 15px;
              border-radius: 4px;
              cursor: pointer;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              .fillter-icon {
                margin-right: 5px;
              }

              .arrow-icon {
                margin-left: 5px;
                margin-right: 0;
              }
            }
          }
        }

        .collapse-expand {
          display: inline-block;
          vertical-align: top;
          min-height: 440px;
          width: 100%;
          padding: 20px 0 0 0;
          position: relative;

          &::after {
            position: absolute;
            right: -25px;
            top: 0;
            content: "";
            height: 100%;
            width: 3px;
            border-radius: 3px;
            background-image: url(../../img/chart-line.png);
            background-position: -12px top;
            background-repeat: no-repeat;
            background-size: cover;
          }

          &.last {
            &::after {
              display: none;
            }
          }

          .heading {
            display: block;
            width: 100%;
            text-align: left;

            h3 {
              font-size: 16px;
              line-height: 22px;
              color: #383874;
              font-weight: 600;
              display: block;
              margin: 0;
              letter-spacing: 0.5px;
            }

            .product-cost {
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
              margin: 10px 0 0 0;

              label {
                font-size: 24px;
                line-height: 26px;
                color: #383874;
                font-weight: 400;
                margin: 0;
                letter-spacing: 0.5px;
              }

              span {
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                color: #15c64c;
                position: relative;
                padding-left: 15px;

                &::before {
                  content: "\f0de";
                  position: absolute;
                  top: 6px;
                  left: 5px;
                  font-weight: 900;
                  font-size: 12px;
                  color: #15c64c;
                  font-family: "FontAwesome";
                }
              }
            }
          }

          .chart-contant {
            margin: 5px 0 0px 0;
            text-align: -webkit-center;

            .chart {
              justify-content: center;
            }

            .chart-details {
              width: 100%;
              display: block;
              margin-top: 30px;

              ul {
                padding: 0;
                margin: 0;

                li {
                  list-style: none;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin: 0 0 5px 0;
                  padding: 0;

                  p {
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 400;
                    color: #383874;
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .right-contant {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    label {
                      font-size: 12px;
                      line-height: 20px;
                      font-weight: 400;
                      color: #383874;
                      margin: 0 5px 0 0;
                    }

                    span {
                      display: inline-block;
                      width: 100px;
                      height: 6px;
                      background-color: #f2f2f2;
                      border-radius: 5px;
                      position: relative;
                      overflow: hidden;

                      span {
                        display: block;
                        width: 100%;
                        border-radius: 3px;
                        position: absolute;
                        left: 0;
                        bottom: 0;

                        &.crocus-purple {
                          background: #8676ff;
                        }

                        &.orange {
                          background: #ff9066;
                        }

                        &.yellow {
                          background: #ffcc41;
                        }

                        &.paris-green {
                          background: #42cd7e;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.chart-tooltip-contents {
  display: block;
  width: 200px;
  background-color: #1e2129;
  border-radius: 5px;

  .cost-text {
    position: relative;
    padding: 15px 15px 0 35px;
    display: block;
    color: #ffffff;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;

    &::before {
      position: absolute;
      left: 15px;
      top: 15px;
      content: "";
      width: 14px;
      height: 14px;
      border-radius: 5px;
      border: 1px solid #c6c1d5;
      background-color: #b399ff;
    }
  }

  .value {
    display: block;
    padding: 7px 15px 10px 15px;
    color: #ffffff;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
  }

  .previous-month-data {
    display: block;
    padding: 0 0px 15px 15px;
    color: #c4c5c9;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;

    span {
      color: #5cef8b;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .name {
    display: block;
    padding: 10px 15px;
    color: #c4c5c9;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    border-top: 1px solid #2c2e36;
  }
}

.summary-loader {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}