.user-profile-container {
  width: 100%;
  display: block;
  padding: 30px 45px;
  .user-profile-details {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
    label {
      display: block;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      color: #383874;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0;
      li {
        display: inline-block;
        width: auto;
        padding: 5px 30px;
        border-right: 1px solid #1c1c1c1a;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          border-right: none;
        }
        span {
          display: block;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          color: #383874;
          margin-bottom: 5px;
        }
        strong {
          display: block;
          font-size: 14px;
          line-height: 18px;
          color: #383874;
          .green {
            color: #00b929;
          }
        }
      }
    }
  }
  .services-panel-tabs {
    display: block;
    width: 100%;
    .tabs-head {
      h4 {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
      }
      .overview-buttons {
        ul {
          background: none;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0;
          margin: 0;
          li {
            display: inline-block;
            list-style: none;
            padding: 0;
            margin-right: 15px;
            width: auto;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .permission-tabs-content {
      display: block;
      width: 100%;
      .credentials-content {
        display: block;
        width: 100%;
        .credentials-box {
          background: #ffffff;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
          .head {
            margin-left: -20px;
            margin-right: -20px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 20px;
            border-bottom: 1px dashed #414d55;
            h4 {
              margin: 0;
            }
          }
          .credentials-details {
            width: 100%;
            display: block;
            padding-top: 20px;
            label {
              display: block;
              font-size: 16px;
              line-height: 20px;
              font-weight: 500;
              color: #383874;
              margin-bottom: 15px;
            }
            span {
              display: block;
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: #383874;
              margin-bottom: 30px;
            }
            p {
              display: block;
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: #00b929;
            }
          }
        }
      }
      .multi-authentication-section {
        display: block;
        width: 100%;
        .environment-table-section {
          padding-top: 0;
          padding-bottom: 0;
          height: auto;
          border-radius: 10px;
          overflow: hidden;
          background-color: #ffffff;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);

          .table {
            table {
              thead {
                tr {
                  background-color: #ffffff;
                  border-bottom: 1px dotted #b3b3b3;
                  th {
                    
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 15px;
                    padding-right: 15px;
                    font-size: 13px;
                    line-height: 25px;
                    font-weight: 500;
                    width: auto;
                    white-space: nowrap;

                    .environment-image {
                      margin: 0px;
                      width: 24px;
                      height: 24px;
                      padding: 2px;
                    }
                  }
                }
              }

              tbody {
                background: #ffffff;
                tr {
                  td {
                    padding-left: 15px;
                    padding-right: 15px;
                    width: auto;
                    white-space: nowrap;
                    border-bottom: none;
                    .billing-section {
                      min-height: 200px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      .billing-details {
                        width: 60%;
                        height: 60px;
                        padding: 15px;
                        background-color: #fcfcfd;
                        display: flex;
                        border-radius: 5px;
                        align-items: center;
                        justify-content: space-between;
                        .note-icon {
                          width: 40px;
                          height: 40px;
                          border-radius: 50%;
                          background: #ebeff7;
                          color: #3772ff;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding: 5px;
                          margin-right: 8px;
                        }
                        span {
                          font-size: 14px;
                          line-height: 20px;
                          font-weight: 500;
                          color: #3772ff;
                        }
                        .close-icon {
                          width: 25px;
                          height: 25px;
                          text-align: center;
                          line-height: 25px;
                          border-radius: 50%;
                          border: 1px solid #eef0fa;
                          i {
                            line-height: 25px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
