.service-details-modal-container {
  width: 380px !important;
  box-shadow: 0px 0px 16px rgba(59, 72, 89, 0.2392156863);
  border-radius: 10px;
  background: white;
  margin: 0 auto;

  .modal-content {
    padding: 20px;

    .modal-header {
      margin: 0 -20px;
      padding: 0 20px 10px;
      .modal-title {
        text-align: left !important;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;

        h5 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          color: #344054;

          .close-btn {
            margin-left: auto;
            cursor: pointer;
          }
        }
      }
    }

    .modal-body {
      .service-details {
        width: 100%;
        display: block;
        ul {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #ebebf0;
            padding: 10px 0;
            label {
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: #383874;
              display: inline-block;
              margin: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 40%;
            }
            span {
              text-align: end;
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: #383874;
              display: inline-block;
              margin: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 50%;
            }
          }
        }
      }
    }
  }
}

.select-lendingzone-modal-container {
  width: 800px !important;
  max-height: 500px;
  box-shadow: 0px 0px 16px rgba(59, 72, 89, 0.2392156863);
  border-radius: 10px;
  background: white;
  margin: 0 auto;

  .modal-content {
    padding: 20px;
    height: 80%;

    .modal-header {
      margin: 0 -20px;
      padding: 0 20px 10px;
      .modal-title {
        text-align: left !important;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;

        h5 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          color: #344054;

          .close-btn {
            margin-left: auto;
            cursor: pointer;
          }
        }
      }
    }

    .modal-body {
      height: 100%;
      padding-top: 20px;
      .select-landing-cards {
        overflow: hidden;
        overflow-y: auto;
        max-height: 330px;
        padding: 0 5px;
        p {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: #000000;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }
        a {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #383874;
          cursor: pointer;
          text-decoration: underline;
          &:hover {
            color: #023aff;
          }
        }
        .select-landing-card {
          width: 100%;
          padding: 10px 5px;
          border-radius: 5px;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
            rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          cursor: pointer;

          .card-content {
            width: 100%;

            .card-image {
              padding: 6px;
              margin: 0 auto;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              border: 1px solid #efefef;
            }

            .card-title {
              font-size: 10px;
              line-height: 14px;
              font-weight: 400;
              color: #000000;
              margin: 8px 0;
            }
          }

          .card-footer {
            padding: 5px 8px;
            border-radius: 5px;
            background: #f6f6f6;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .footer-left-content {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 70%;
              span {
                font-size: 12px;
                line-height: 15px;
                color: #535362;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 600;
              }

              label {
                font-size: 10px;
                line-height: 14px;
                font-weight: 500;
                color: #00b929;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .footer-right-content {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 30%;
              span {
                font-size: 10px;
                line-height: 14px;
                color: #535362;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              label {
                font-size: 10px;
                line-height: 14px;
                font-weight: 500;
                color: #000000;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          &.active {
            border: 1px solid #384cff;
          }
        }
      }
    }
  }
}
