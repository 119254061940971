.configure-topology-container {
  width: 100%;
  display: block;
  padding: 15px 20px 50px;
  background-color: #f6f7fb;

  h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    color: #383874;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #000000;
  }

  .configur-head {
    .button-group {
      display: flex;
      background: #ffffff;
      padding: 10px 20px;
      border-radius: 5px;

      .primary-custom-btn {
        margin-left: auto;
        padding-left: 30px;
        padding-right: 30px;

        &::after {
          content: "";
          top: 0;
          left: -22px;
          width: 1px;
          height: 100%;
          background: #edeff8;
          position: absolute;
        }
      }
    }
  }

  .configur-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;

    .api-server {
      margin-right: 2%;
      display: inline-flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      width: 32%;
      padding: 30px 30px;
      border-radius: 10px;
      background: #ffffff;
      min-height: 620px;
      box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07);

      &.min-width {
        width: 398px;
      }

      &:last-child {
        margin-right: 0;
      }

      h2 {
        font-weight: 500;
      }

      .plus-icon {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        margin: 15px auto;
        background: #3b7cff;
        color: #ffffff;
      }

      .down-arrow {
        font-size: 16px;
        margin: 10px 0;
        color: #383874;
      }

      .eks-logo-boxs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 15px;
        margin-bottom: 15px;
        padding-bottom: 15px;

        .box-arrow {
          font-size: 16px;
          color: #383874;
        }

        .eks-logo {
          width: 40px;
          height: 40px;
          align-items: center;
          justify-content: center;
          display: flex;
          border-radius: 5px;
          background: #f4f4f4;
          margin: 10px 10px 5px 10px;

          img {
            max-width: 50%;
          }
        }

        .title {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          color: #383874;
        }
      }

      .progress-bar {
        width: 100%;
        height: 20px;
      }

      .address-content {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          i {
            font-size: 30px;
            color: #faa24b;
            margin-right: 10px;
          }
        }

        p {
          font-size: 24px;
          line-height: 30px;
          font-weight: 800;
          color: #384cff;
          margin: 0;
        }

        i {
          font-size: 30px;
          color: #384cff;
          margin-left: 10px;
        }
      }
    }

    .balancing-container {
      margin-bottom: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 50px 30px;
      border-radius: 10px;
      background: #ffffff;
      min-height: 350px;
      box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07);

      .balancing-content {
        .image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          min-height: 90px;
          img {
            max-height: 90px;
            opacity: 0.7;
          }
        }
        .name {
          display: block;
          color: #000000;
          font-size: 18px;
          line-height: 24px;
          font-weight: 500;
          padding-top: 20px;
        }
      }

      .storage-content {
        padding-bottom: 40px;
        .image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          min-height: 50px;
          img {
            max-height: 50px;
          }
        }
        .name {
          display: block;
          color: #000000;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          padding-top: 15px;
        }
      }

      .add-extra-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .extra-btn {
          width: 48px;
          height: 48px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px;
          font-size: 24px;
          line-height: 32px;
          padding: 0;
          min-width: auto;
          color: #ffffff;
          background-color: #384cff;
        }
        strong {
          display: flex;
          justify-content: center;
          width: 100%;
          color: #000000;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          padding-top: 5px;
        }
      }
    }

    .application-servers-container {
      display: inline-flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 15px 15px;
      border-radius: 10px;
      background: #ffffff;
      min-height: 350px;
      box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07);

      .heading {
        justify-content: center;
        align-items: center;
        color: #000000;
        font-size: 26px;
        line-height: 30px;
        font-weight: 400;
        i {
          color: #a1a3d4;
          margin-left: 10px;
        }
      }

      .application-vertical-slider {
        border-right: 2px dotted #000000;
        .application-cards-slider {
          width: 300px;
          display: block;
          .reserved-card {
            width: 140px;
            margin-left: 10px;
            margin-right: 10px;
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            &:after {
              left: 70px;
            }
          }
        }
      }

      .horizontal-add-node {
        .primary-outline-btn {
          font-size: 16px;
          margin-right: 3px;
        }

        .node-box {
          display: block;
          text-align: center;
          .image {
            min-height: 80px;
            img {
              max-height: 80px;
            }
          }
          .name {
            display: inline-block;
            padding-top: 10px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: #000000;
          }
        }
      }

      .scaling-contents {
        display: block;
        width: 100%;
        .heading {
          display: block;
          width: 100%;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 3px;
          background-color: #f2f3f7;

          strong {
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            line-height: 30px;
            font-weight: 600;
            color: #000000;
          }
          i {
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            line-height: 30px;
            font-weight: 600;
            color: #1b4dff;
          }
        }
        .contents {
          display: block;
          width: 100%;
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 8px;
          .text {
            display: inline-block;
            vertical-align: top;
            font-size: 11px;
            line-height: 20px;
            font-weight: 600;
            color: #000000;
            i {
              color: #a1a3d4;
              margin-left: 5px;
              vertical-align: middle;
            }
          }

          .form-control {
            padding: 0;
            border: none;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            input {
              display: inline-flex;
              font-size: 11px;
              line-height: 14px;
              font-weight: 500;
              width: 53px;
              padding: 3px 5px;
              border: none;
              border-radius: 3px;
              background-color: #dbdff1;

              &::placeholder {
                color: #000000;
              }
            }

            .dropdown-arrow {
              display: block;
              line-height: 1.2px;
              margin-left: 5px;
              color: #1B4DFFED;

              i {
                cursor: pointer;
                font-size: 10px;
                line-height: 10px;
              }
            }
          }
        }
      }
    }
  }

  .application-cards-slider {
    display: inline-block;
    width: 338px;
    margin-bottom: auto;
  }

  .application-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .reserved-card {
      width: 149px;
      padding: 10px 8px;
      border-radius: 10px;
      background: #384cff;
      margin: 0 20px 30px;
      position: relative;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &::after {
        background-color: #384cff;
        content: "";
        position: absolute;
        left: 72px;
        bottom: -24px;
        width: 1px;
        height: 25px;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;

        h4 {
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          color: #ffffff;
          margin: 0;
        }

        i {
          font-size: 12px;
          color: #ffffff;
        }
      }

      .reseved-content {
        input {
          width: 24px;
          border-radius: 3px;
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
          border: none;
          text-align: center;
          padding: 0;
        }

        .dropdown-arrow {
          display: block;
          line-height: 1.2px;
          margin-left: 5px;
          color: #ffffff;

          i {
            cursor: pointer;
            font-size: 10px;
            line-height: 10px;
          }
        }

        span {
          font-size: 9px;
          line-height: 14px;
          font-weight: 500;
          color: #ffffff;
        }

        p {
          margin: 10px 0 0 0;
          font-size: 9px;
          line-height: 14px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }

  .slider-main {
    position: relative;
    display: block;
    width: 100%;

    .slider-line {
      background-color: #384cff;
      height: 1px;
      position: absolute;
      top: -22px;

      span {
        background-color: #ffffff;
        height: 1px;
        display: inline-block;
        position: relative;
        z-index: 1;
      }
    }

    .slider {
      .MuiSlider-rail {
        color: #eaecf0;
        opacity: 1;
      }

      .MuiSlider-track {
        color: #384cff;
      }

      .MuiSlider-thumb {
        color: #ffffff;
        border: 1px solid #384cff;

        &::before {
          content: "";
          background-color: #384cff;
          left: 9px;
          top: -27px;
          width: 1px;
          height: 26px;
        }
      }

      .MuiSlider-valueLabelOpen {
        background-color: transparent;
        color: #101828;
        padding: 0;
        top: 45px;

        &::before {
          display: none;
        }
      }
    }
  }
}
