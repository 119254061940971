.alert-tab-section {
  width: 100%;
  display: block;
  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #b3b3b3;
    .tabs-menu {
      padding-bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      li {
        padding-bottom: 12px;
        font-size: 13px;
        font-weight: 600;
        color: #383874;
        cursor: pointer;
        width: 150px;
        display: inline-block;
        text-align: center;
        position: relative;

        &::after {
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
          content: "";
          opacity: 0;
          position: absolute;
          background-color: #8676ff;
          border-top-left-radius: 50px;
          border-top-right-radius: 50px;
          box-shadow: rgba(134, 118, 255, 0.15) 0px 2px 1px,
            rgba(134, 118, 255, 0.15) 0px 4px 2px,
            rgba(134, 118, 255, 0.15) 0px 8px 4px,
            rgba(134, 118, 255, 0.15) 0px 16px 8px,
            rgba(134, 118, 255, 0.15) 0px 32px 16px;
        }

        &.active {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
  .tabs-content {
    width: 100%;
    display: block;
    padding-top: 25px;
    .reports-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 420px;
    }
    .affected-resource {
      background: #ffffff;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
          margin: 0;
          padding: 0;
          display: inline-block;
          label {
            display: block;
            width: 100%;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #383874;
            margin-bottom: 8px;
          }
          .high {
            width: 10px;
            min-width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #ff2d2e;
            margin-right: 5px;
          }
          span {
            display: block;
            width: 100%;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: #383874;
          }
        }
      }
    }
    .alert-details {
      background: #ffffff;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
      .title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #383874;
        margin-bottom: 15px;
      }
      .details-head {
        border-bottom: 1px dashed #b3b3b3;
        margin: 0 -20px 15px;
        padding: 0 20px 15px;
        ul {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          li {
            padding: 0;
            margin: 0;
            display: block;
            label {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #383874;
              span {
                display: inline-block;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #384cff;
              }
            }
            span {
              font-size: 12px;
              line-height: 16px;
              font-weight: 400;
              color: #383874;
              display: block;
            }
          }
        }
      }
      .alert-details-content {
        width: 100%;
        display: block;
        ul {
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          li {
            width: 25%;
            display: block;
            padding: 0;
            margin-bottom: 10px;
            label {
              display: block;
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #383874;
              margin-bottom: 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 90%;
            }
            span {
              font-size: 12px;
              line-height: 16px;
              font-weight: 400;
              color: #383874;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 90%;
            }
          }
        }
      }
    }
  }
}
