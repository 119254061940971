.environment-container {
  &.associate-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    padding-bottom: 30px;

    .mapping-breadcrumbs {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      border-radius: 8px;
      margin-bottom: 10px;
      background-color: #ffffff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
      ul {
        list-style: none;
        display: flex;
        li {
          display: inline-flex;
          padding-left: 0px;
          padding-right: 0px;
          line-height: 32px;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          color: #000000;
          span {
            display: block;
            color: #384cff;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 5px;
          }
          &:last-child {
            span {
              background-color: #416bff33;
              margin-left: 5px;
              cursor: auto;
            }
          }
        }
      }
    }

    .associate-chart-container {
      display: block;
      padding: 15px;
      margin-top: 20px;
      width: 100%;
      height: 406px;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198);

      h4 {
        display: block;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        color: #383874;
        margin: 0;
      }

      .mapping-fliter {
        margin-right: 15px;
        display: inline-block;
        width: auto;
        position: relative;

        .fliter-toggel {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: none;
          background: #dde1f8;
          color: #383874;
          font-size: 12px;
          font-weight: 600;
          height: 36px;
          padding: 0 15px;
          border-radius: 4px;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .arrow-icon {
            margin-left: 20px;
          }
        }

        .fliter-collapse {
          background-color: #ffffff;
          border-radius: 5px;
          padding: 3px;
          display: none;
          position: absolute;
          top: 38px;
          width: 172px;
          left: 0;
          z-index: 10;
          overflow: hidden;
          overflow-y: auto;
          max-height: 200px;
          box-shadow: 0px 4px 32px 0px #00103d29;

          &.active {
            display: block;
          }

          ul {
            display: block;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 0;
              margin-bottom: 0;
              color: #383874;
              font-size: 12px;
              line-height: 16px;
              font-weight: 500;
              padding: 7px 10px;
              border-radius: 3px;
              cursor: pointer;
              span{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &:hover {
                color: #383874;
                background-color: #dde1f8;
              }
              &.active {
                color: #383874;
                background-color: #dde1f8;
              }

              i {
                margin-right: 7px;
              }
            }
          }
        }

        .fliters-collapse-bg {
          position: fixed;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 1;
          display: none;

          &.active {
            display: block;
          }
        }
      }

      .loading {
        margin-top: 30px;
        height: calc(90% - 30px);
        justify-content: center;
      }

      .chart-container {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        height: 300px;
        // padding-left: 15px;
        // padding-right: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;

        > div {
          width: 100%;
          padding-right: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .react-transform-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-top: 1px;

          .react-transform-component {
            flex-wrap: nowrap;
          }
        }

        svg {
          z-index: 1;
        }

        .gmnoprint {
          position: absolute;
          bottom: 0px;
          right: 0px;
          width: 30px;
          z-index: 1;

          .gmnoprint-plus-minus {
            display: block;
            width: 100%;
            margin-bottom: 10px;
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);

            .btn {
              display: block;
              color: #023aff;
              font-size: 14px;
              line-height: 30px;
              width: 30px;
              height: 30px;
              border: none;
              cursor: pointer;
              padding: 0;
              text-align: center;
              background-color: #ffffff;
            }
          }

          .gmnoprint-map {
            display: block;
            width: 100%;
            margin-bottom: 10px;
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);

            .btn {
              display: block;
              color: #023aff;
              font-size: 14px;
              line-height: 30px;
              width: 30px;
              height: 30px;
              border: none;
              padding: 0;
              cursor: pointer;
              text-align: center;
              background-color: #ffffff;
            }
          }
        }

        .chart-box {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 130px;
          height: 68px;
          padding-left: 5px;
          padding-right: 5px;
          border-radius: 15px;
          margin-right: 50px;
          border: 1px solid #c7c7e8;
          box-shadow: 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198);

          &.active {
            border-color: #53ca43;
            border-width: 2px;
          }
        }

        .global-servies {
          display: inline-flex;
          width: auto;
          margin-right: 48px;

          ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;

            li {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              cursor: pointer;
              position: relative;
              width: 160px;
              height: 52px;
              padding: 0px;
              margin-bottom: 20px;
              border-radius: 15px;
              border: 1px solid #c7c7e8;
              box-shadow: 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198);

              &::before {
                position: absolute;
                width: 162px;
                height: 54px;
                left: -2px;
                top: -2px;
                content: "";
                border-radius: 15px;
                border-width: 1px;
                border-style: solid;
                border-color: transparent;
              }

              i {
                position: absolute;
                right: -7px;
                top: 17px;
                color: #c7c7e8;
                font-size: 14px;
                background-color: #3838748c;
                border-radius: 50%;
              }

              .tooltip-content {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                position: relative;
                width: 160px;
                height: 52px;
                padding: 10px 10px;
              }

              span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 26px;
                height: 26px;
                border-radius: 50px;
                background-color: #dde1f8;
                margin-right: 10px;
              }

              .content {
                display: inline-flex;
                flex-wrap: wrap;
                width: calc(100% - 36px);
                p {
                  margin: 0;
                  width: 100%;
                  font-size: 12px;
                  line-height: 12px;
                  color: #383874;
                  font-weight: 400;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .box {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: 2px;
                  width: auto;
                  padding-left: 5px;
                  padding-right: 5px;
                  height: 16px;
                  font-size: 10px;
                  line-height: 16px;
                  color: #ffffff;
                  font-weight: 400;
                  position: relative;
                  text-transform: uppercase;

                  &:after {
                    content: "";
                    position: absolute;
                    right: -8px;
                    top: 0px;
                    border-top: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                  }

                  &.blue {
                    background-color: #8676ff;
                    &:after {
                      border-left: 8px solid #8676ff;
                    }
                  }
                  &.orange {
                    background-color: #ffba69;
                    &:after {
                      border-left: 8px solid #ffba69;
                    }
                  }
                }
              }

              &.active {
                border-color: #53ca43;
                &::before {
                  border-color: #53ca43;
                }
                i {
                  color: #53ca43;
                  background-color: #ffffff;
                }
              }
            }
          }

          &:last-child {
            margin-right: 15px;
          }
        }
      }
    }

    .infra-existing {
      display: block;
      padding: 15px;
      margin-top: 0px;
      width: 100%;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198);

      .heading {
        display: block;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #383874;
        margin: 0 0 10px 0;
      }

      .breadcrumbs {
        display: block;
        width: 100%;
        border-radius: 10px;
        padding: 12px 15px 8px;
        border: 1px solid #8676ff;
        max-height: 400px;
        overflow-y: auto;
        overflow: auto;
        ul {
          display: block;
          width: 100%;
          padding: 0;
          margin: 0 0 5px 0;
          &:last-child {
            margin-bottom: 0;
          }
          li {
            display: inline-block;
            vertical-align: top;
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            color: #383874;
            padding-left: 15px;
            padding-right: 15px;
            position: relative;
            cursor: pointer;

            &.active {
              font-weight: 600;
              color: #000000;
              a {
                font-weight: 600;
                color: #000000;
              }
              &:nth-child(2) {
                &::before {
                  background-color: #1b4dffed;
                }
                &:after {
                  border-left: 2px solid #1b4dffed;
                }
              }
              &:nth-child(3) {
                &::before {
                  background-color: #1b4dffed;
                }
                &:after {
                  border-left: 2px solid #1b4dffed;
                }
              }
              &:nth-child(4) {
                &::before {
                  background-color: #1b4dffed;
                }
                &:after {
                  border-left: 2px solid #1b4dffed;
                }
              }
              &:nth-child(5) {
                &::before {
                  background-color: #1b4dffed;
                }
                &:after {
                  border-left: 2px solid #1b4dffed;
                }
              }
              &:nth-child(6) {
                &::before {
                  background-color: #1b4dffed;
                }
                &:after {
                  border-left: 2px solid #1b4dffed;
                }
              }
              &:nth-child(7) {
                &::before {
                  background-color: #1b4dffed;
                }
                &:after {
                  border-left: 2px solid #1b4dffed;
                }
              }
            }

            a {
              padding-left: 0;
              padding-right: 0;
              color: #383874;
              font-weight: 400;
              cursor: pointer;
            }
            &::before {
              content: "";
              width: 8px;
              height: 8px;
              position: absolute;
              left: 0;
              top: 6px;
            }
            &:after {
              content: "";
              position: absolute;
              left: 8px;
              top: 6px;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
            }
            &:nth-child(1) {
              &::before {
                background-color: #1b4dffed;
              }
              &:after {
                border-left: 2px solid #1b4dffed;
              }
            }
            &:nth-child(2) {
              &::before {
                background-color: #faa24b;
              }
              &:after {
                border-left: 2px solid #faa24b;
              }
            }
            &:nth-child(3) {
              &::before {
                background-color: #8676ff;
              }
              &:after {
                border-left: 2px solid #8676ff;
              }
            }
            &:nth-child(4) {
              &::before {
                background-color: #fa6298;
              }
              &:after {
                border-left: 2px solid #fa6298;
              }
            }
            &:nth-child(5) {
              &::before {
                background-color: #c6cbd9;
              }
              &:after {
                border-left: 2px solid #c6cbd9;
              }
            }
            &:nth-child(6) {
              &::before {
                background-color: #383874;
              }
              &:after {
                border-left: 2px solid #383874;
              }
            }
            &:nth-child(7) {
              &::before {
                background-color: #53ca43;
              }
              &:after {
                border-left: 2px solid #53ca43;
              }
            }
            &:last-child {
              &::after {
                display: none;
              }
              &::before {
                display: none;
              }
              a {
                background-color: transparent;
                margin-left: 0;
              }
            }
          }
        }
        button {
          display: inline-block;
          min-width: inherit;
          height: 20px;
          padding: 0;
          width: 20px;
          line-height: 22px;
          font-size: 10px;
          color: #ffffff;
          border-radius: 50%;
          background-color: #8198be;
          &:hover {
            background-color: red;
          }
        }
      }
    }
  }
}
