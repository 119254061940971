.forget-container {
  width: 100%;
  height: 100%;
  display: flex;

  .forget-left {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
    padding: 70px 75px;

    .forget-left-content {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .back-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
          padding-left: 10px;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
      }
      .open-email-bottom-content {
        text-align: center;
        width: 100%;
        margin-top: 60px;
        p {
          width: 100%;
          margin-top: 10px;
          display: block;
          width: 100%;
          font-size: 11px;
          line-height: 14px;
          font-weight: 400;
          color: #9999a3;
          a {
            color: #8676ff;
          }
        }
      }

      .forget-text {
        margin-top: 55px;
        margin-bottom: 40px;

        h2 {
          font-weight: 600;
          margin-bottom: 15px;
        }

        p {
          margin-top: 10px;
          display: block;
          width: 100%;
          font-size: 11px;
          line-height: 14px;
          font-weight: 400;
          color: #9999a3;
        }
      }

      .input-group {
        display: block;
        width: 80%;
        position: relative;
        margin-bottom: 15px;
        i {
          position: absolute;
          top: 33px;
          right: 10px;
          color: #a0a0b5;
          cursor: pointer;
        }

        label {
          margin-bottom: 5px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: #344054;
        }

        .form-control {
          width: 100%;
          height: 32px;
          padding: 2px 10px;
          border: 1px solid #dcdfe5;
          border-radius: 5px;
          font-size: 13px;
          line-height: 32px;
          font-weight: 300;
          color: #495057;

          &::placeholder {
            color: #a0a0b5;
          }
        }

        p {
          font-size: 12px;
          line-height: 16px;
          margin-top: 3px;
          color: red;
        }
      }

      .next-step {
        margin-top: 15px;

        p {
          margin: 0;
          margin-left: auto;
          color: #9a9ab0;
          font-size: 13px;
          line-height: 30px;
          font-weight: 400;

          a {
            font-weight: 600;
            color: #5541d7;
            margin-left: 5px;
          }
        }
        .primary-btn {
          a {
            color: #fff;
          }
        }
      }
    }
  }

  .forget-right {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    background: #e2e2ea;
    padding: 0 50px;
  }
}
