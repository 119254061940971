.resource-section {
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
  label {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #383874;
  }
  .select-resource {
    margin-right: 15px;
    display: inline-block;
    width: auto;
    position: relative;

    .fliter-toggel {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      background: #ffffff;
      border: 1px solid #384cff;
      color: #383874;
      font-size: 12px;
      font-weight: 600;
      height: 36px;
      padding: 0 15px;
      border-radius: 4px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.new-environment {
        background-color: transparent;
        border: 1px solid #8676ff;
      }

      .fillter-icon {
        margin-right: 5px;
      }

      .arrow-icon {
        margin-left: 5px;
        margin-right: 0;
      }
    }

    .fliter-collapse {
      background-color: #ffffff;
      border-radius: 4px;
      padding: 10px 10px 0;
      display: none;
      position: absolute;
      top: 40px;
      width: 220px;
      left: 0;
      z-index: 10;
      overflow: hidden;
      overflow-y: auto;
      max-height: 200px;
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);

      &.active {
        display: block;
      }

      .search-bar {
        width: 100%;
        display: block;
        border-bottom: 1px solid #383874;
        padding-bottom: 5px;
        margin-bottom: 10px;

        input {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: #383874;
          border: none;
          width: 100%;

          &:focus-visible {
            outline: none;
          }
        }
      }

      ul {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 0;
          padding-right: 0;
          padding-top: 0;
          padding-bottom: 10px;
          margin-bottom: 0;
          color: #383874;
          font-size: 12px;
          font-weight: 400;
          cursor: pointer;

          input {
            width: 13px;
            height: 13px;
            margin: 0 5px 0 0;
            accent-color: #384cff;
          }

          a {
            color: #414d55;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &:hover {
              p {
                color: #3b7cff;
              }
            }

            p {
              display: inline-block;
              vertical-align: top;
              margin-bottom: 0;
              margin-top: 0px;
              font-size: 12px;
              line-height: 15px;
              font-weight: 500;
              color: #414d55;
            }

            span {
              display: inline-block;
              vertical-align: top;
              text-align: center;
              width: 24px;
              height: 15px;
              line-height: 15px;
              margin-right: 10px;

              &.image-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                padding: 5px;
                border-radius: 3px;
                height: 30px;
                background: #fff;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
              }

              img {
                max-width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }

    .fliters-collapse-bg {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      display: none;

      &.active {
        display: block;
      }
    }
  }
}
