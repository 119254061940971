.tier-container {
  display: block;
  width: 100%;
  margin-top: 25px;
  
  .nginx-cards {
    background: #ffffff;
    border-radius: 5px;
    display: block;
    height: 455px;
    overflow: hidden;
    // overflow-x: auto;
    width: 100%;
    padding: 25px 20px;
    .deployed-content {
      display: block;
      width: 100%;
    }
  }
  .nginx-section {
    background: #ffffff;
    border-radius: 10px;
    display: block;
    overflow: hidden;
    overflow-x: auto;
    margin-top: 25px;
    width: 100%;
    box-shadow: 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198);

    .tabs {
      display: block;
      width: 100%;

      .tabs-menu {
        width: 100%;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #ffffff;
        border-bottom: 1px solid #b3b3b3;

        li {
          padding-bottom: 16px;
          font-size: 13px;
          font-weight: 600;
          color: #383874;
          cursor: pointer;
          width: auto;
          display: inline-flex;
          position: relative;

          &::after {
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            content: "";
            opacity: 0;
            position: absolute;
            background-color: #8676ff;
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
            box-shadow: rgba(134, 118, 255, 0.15) 0px 2px 1px,
              rgba(134, 118, 255, 0.15) 0px 4px 2px,
              rgba(134, 118, 255, 0.15) 0px 8px 4px,
              rgba(134, 118, 255, 0.15) 0px 16px 8px,
              rgba(134, 118, 255, 0.15) 0px 32px 16px;
          }

          &.active {
            &::after {
              opacity: 1;
            }
          }
        }
      }

      .tabs-content {
        padding: 0 20px;
        margin: 0;
        .prometheus-endpoint{
          label{
            display: flex;
            width: 100%;
            font-size: 14px;
            color: #344054;
            line-height: 20px;
            font-weight: 500;
            margin-bottom: 10px;
           
          }
        }
        .ports-content {
          width: 100%;
          display: block;
          margin-top: 20px;
          .ports-inner-content {
            display: block;
            width: 100%;
            .ports-public-section {
              width: 100%;
              display: inline-flex;
              align-items: center;
              ul {
                display: block;
                padding: 0 30px 20px;
                margin: 0;
                align-items: flex-end;
                justify-content: flex-start;

                li {
                  display: block;
                  padding: 0;
                  margin: 0;
                  width: auto;
                  text-align: center;
                  z-index: 1;
                  .ports-public {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    flex-wrap: wrap;
                    .public-ip {
                      width: 200px;
                      background: #ffba69;
                      border-radius: 5px;
                      height: 35px;
                      color: #ffffff;
                      font-size: 14px;
                      line-height: 35px;
                      font-weight: 400;
                      text-align: center;
                      &.green {
                        background: #53ca43;
                      }
                      &.blue {
                        background: #3697ef;
                        width: auto;
                        padding: 0 20px;
                      }
                    }
                    .balancer-boxs {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-wrap: wrap;
                      margin-bottom: 10px;
                      z-index: 1;
                      .balancer-box {
                        width: 36px;
                        margin-left: 15px;
                        margin-right: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;
                        .green {
                          color: #53ca43;
                        }
                        .blue {
                          color: #1b4dff;
                        }
                        .orange {
                          color: #ffba69;
                        }
                        &:nth-child(1) {
                          .icon {
                            border: 1px solid #53ca43;
                          }
                        }
                        &:nth-child(2) {
                          .icon {
                            border: 1px solid #1b4dff;
                          }
                        }
                        &:nth-child(3) {
                          .icon {
                            border: 1px solid #ffba69;
                          }
                        }
                        span {
                          display: flex;
                          justify-content: center;
                          width: 100%;
                        }

                        p {
                          display: flex;
                          width: 100%;
                          justify-content: center;
                          text-align: center;
                          margin: 0;
                          font-size: 12px;
                          line-height: 16px;
                          font-weight: 600;
                          color: #383874;
                        }
                      }
                    }
                  }
                  .cluster-box {
                    ul {
                      padding: 0;
                      margin: 0;
                      position: relative;
                      &::after {
                        content: "";
                        border: 1px dashed #000000;
                        position: absolute;
                        top: 18px;
                        left: 0;
                        right: 0;
                        width: 40%;
                        height: 50%;
                        border-radius: 8px;
                        margin: 0 auto;
                      }
                      li {
                        .public-ip {
                          width: auto;
                          padding: 0 20px;
                          background: #ffba69;
                          border-radius: 5px;
                          height: 35px;
                          color: #ffffff;
                          font-size: 14px;
                          line-height: 35px;
                          font-weight: 400;
                          text-align: center;
                          &.green {
                            background: #53ca43;
                          }
                          &.blue {
                            background: #3697ef;
                          }
                        }
                        .balancer-boxs {
                          width: 100%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          flex-wrap: wrap;
                          margin-bottom: 10px;
                          z-index: 1;
                          .balancer-box {
                            width: 36px;
                            margin-left: 0;
                            margin-right: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
                          }
                        }
                      }
                    }
                  }
                  .ingress-boxs {
                    display: inline-flex;
                    flex-wrap: wrap;
                    flex-wrap: wrap;
                    width: 270px;
                    background: #b9bec6;
                    padding: 10px 15px;
                    border-radius: 5px;
                    margin-top: 20px;
                    .ingress-box {
                      width: 30px;
                      min-width: 30px;
                      height: 30px;
                      margin: 5px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background: #ffffff;
                      border-radius: 3px;
                    }
                  }
                }
              }
            }
            .following-section {
              display: flex;
              flex-wrap: wrap;
              .following-ports {
                padding: 15px;
                border-radius: 5px;
                background: #ffba69;
                width: 360px;
                margin-right: 20px;
                margin-bottom: 20px;
                &.alpha-cluster {
                  background: #faa24b;
                }
                &.load-balancer {
                  background: #88e143;
                }
                &.omega-ingress {
                  background: #3697ef;
                }

                ul {
                  display: block;
                  margin: 10px 0 0 0;
                  padding: 0;
                  li {
                    display: block;
                    font-size: 13px;
                    line-height: 18px;
                    font-weight: 400;
                    color: #000000;
                    padding: 0;
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .table-buttom-section{
    position: relative;
    .primary-btn{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
@media (max-width: 1280px) {
  .tier-container {
    .nginx-section {
      .tabs {
        .tabs-content {
          .ports-content {
            width: 100%;
            display: block;
            margin-top: 20px;
            overflow: hidden;
            overflow-x: auto;
            .ports-inner-content {
              width: 1200px;
              overflow: hidden;
              overflow-x: auto;
            }
          }
        }
      }
    }
    
  }
}
