.dashboard-container {
  width: 100%;
  height: 100%;
  display: block;
  margin: -50px 0 0 0;
  padding: 0 60px;
  background-color: #f6f7fb;
  position: relative;
.dashboard-bg-image{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .dashboard-inner {
    width: 850px;
    height: 850px;
    margin: 0 auto;
    border-radius: 50%;
    padding: 75px;
    background: #f6f7fb;
    border: 20px solid rgba(255, 255, 255, 0.49);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;

    .dashboard-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 60px;
      border-radius: 50%;
      background: #ffffff;
      overflow: hidden;
      img {
        max-width: 750px;
        margin-top: 65px;
      }
    }
  }

}
  

  .services-panel-tabs {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    margin-top: 90px;
    text-align: center;

    .tabs-head {
      display: flex;
      width: 100%;
      justify-content: center;
      position: relative;

      h2 {
        margin: 0 0 0 20px;
        font-size: 24px;
        font-weight: 400;
        line-height: 38px;
        color: #383874;
        position: absolute;
        left: 38px;
      }

      ul {
        display: inline-block;
        width: auto;
        padding-top: 1px;
        padding-bottom: 1px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 30px;
        background-color: #ffffff;
        box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07),
          0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198),
          0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275),
          0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035),
          0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725),
          0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);

        li {
          width: auto;
          display: inline-block;
          vertical-align: top;
          font-size: 11px;
          line-height: 20px;
          font-weight: 500;
          color: #000000;
          padding-top: 4px;
          padding-bottom: 4px;
          padding-left: 25px;
          padding-right: 25px;
          border-radius: 30px;
          cursor: pointer;

          &.active {
            color: #ffffff;
            background-color: #3247e5;
          }
        }
      }
    }

    .tabs-content {
      display: block;
      width: 100%;
      padding: 25px 60px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .dashboard-container {
    padding: 0 30px;
    .dashboard-inner {
      width: 750px;
      height: 750px;
      .dashboard-image {
        img {
          max-width: 650px;
          margin-top: 65px;
        }
      }
    }
    .services-panel-tabs {
      .tabs-head {
        h2 {
          margin: 0;
          left: 30px;
        }
        ul li {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      .tabs-content {
        padding: 25px 30px;
      }
    }
  }
}
