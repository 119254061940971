.cost-analysis-container {
    margin-top: 50px;
    width: 100%;
    display: block;

    .product-wise-inner-container {
      display: block;
      width: 100%;

      .main-collapse-expand {
        display: block;
        width: 100%;

        .collapse-expand {
          display: inline-block;
          vertical-align: top;
          width: 100%;
          min-height: 500px;
          padding: 25px 20px;
          flex-wrap: wrap;
          background: #ffffff;
          border-radius: 10px;
          margin-bottom: 0px;
          // margin-left: 1.2%;
          // margin-right: 1.2%;
          box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07),
            0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198),
            0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275),
            0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035),
            0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725),
            0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);
          .spend-loading {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 400px;
            i {
              margin-right: 8px;
            }
          }

          .heading {
            display: block;
            width: 100%;
            text-align: left;

            h3 {
              font-size: 16px;
              line-height: 22px;
              color: #383874;
              font-weight: 600;
              display: block;
              margin: 0;
              letter-spacing: 0.5px;
            }

            .product-cost {
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
              margin: 10px 0 0 0;

              label {
                font-size: 24px;
                line-height: 26px;
                color: #383874;
                font-weight: 400;
                margin: 0;
                letter-spacing: 0.5px;
              }

              span {
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                color: #15c64c;
                position: relative;
                padding-left: 15px;

                &::before {
                  content: "\f0de";
                  position: absolute;
                  top: 6px;
                  left: 5px;
                  font-weight: 900;
                  font-size: 12px;
                  color: #15c64c;
                  font-family: "FontAwesome";
                }
              }
            }
          }

          .chart-contant {
            margin: 5px 0 0px 0;
            text-align: -webkit-center;

            .chart-details {
              width: 100%;
              display: block;
              margin-top: 30px;
              height: 165px;
              overflow-y: auto;

              ul {
                padding: 0;
                margin: 0 5px 0 0;

                li {
                  list-style: none;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin: 0 0 5px 0;
                  padding: 0;

                  p {
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 400;
                    color: #383874;
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .right-contant {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    label {
                      font-size: 12px;
                      line-height: 20px;
                      font-weight: 400;
                      color: #383874;
                      margin: 0 5px 0 0;
                    }

                    span {
                      display: inline-block;
                      width: 160px;
                      height: 6px;
                      background-color: #f2f2f2;
                      border-radius: 5px;
                      position: relative;
                      overflow: hidden;

                      span {
                        display: block;
                        width: 100%;
                        border-radius: 3px;
                        position: absolute;
                        left: 0;
                        bottom: 0;

                        &.crocus-purple {
                          background: #8676ff;
                        }

                        &.orange {
                          background: #ff9066;
                        }

                        &.yellow {
                          background: #ffcc41;
                        }

                        &.paris-green {
                          background: #42cd7e;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1280px){
    .cost-analysis-container {
      .product-wise-inner-container {
        .main-collapse-expand {
          .collapse-expand {
            min-height: 390px;
            .chart-contant {
              .chart-details {
                ul {
                  li {
                    .right-contant {
                      span {
                        width: 100px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } 