.create-group-container {
  width: 100%;
  display: block;
  padding: 30px 45px;
  .grop-description-section {
    padding: 15px 20px;
    background: #ffffff;
    border-radius: 10px;
    .form-group {
      width: 100%;
      .form-label {
        font-size: 14px;
        color: #344054;
        line-height: 20px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      span {
        font-size: 12px;
        color: #344054;
        line-height: 18px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      .form-control {
        width: 100%;
        height: 36px;
        padding: 5px 15px 5px 15px;
        border: 1px solid #d0d5dd;
        background: linear-gradient(0deg, #ffffff, #ffffff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-radius: 5px;
        font-size: 13px;
        line-height: 26px;
        font-weight: 400;
        color: #344054;
        font-family: "Poppins", sans-serif;
        margin-top: 8px;
        &:focus-visible{
          outline: none;
        }
      }
    }
  }
}
