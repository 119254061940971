.progress-cards {
  width: 100%;
  display: block;
  .progress-card {
    width: 100%;
    display: block;
    border-radius: 8px;
    border-top: 2px solid red;
    padding: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    &.green-card{
        border-top: 2px solid #53CA43;
    }
    .card-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        label{
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            color: #383874;
        }
        span{
            font-size: 10px;
            line-height: 14px;
            font-weight: 400;
            color: #383874;
            text-transform: uppercase;
        }
        .timing-content{
            border: 1px solid #E9E9E9;
            padding: 5px 10px;
            border-radius: 5px;
            p{
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                color: #383874;
                margin: 0;
            }
            I{
                color: #383874;
            }
        }
    }
    .card-content{
        width: 100%;
        display: block;
        .remaining-budget{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom:  1px solid #DADADA;
            margin-left: -15px;
            margin-right: -15px;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 20px;
            margin-bottom: 20px;
            padding-bottom: 15px;
            label{
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                color: #383874;
            }
            span{
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                color: #383874;
            }
        }
        .progress-top-content{
            display: flex;
            align-items: center;
            justify-content: space-between;
            // padding-bottom: 5px;
            label{
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                color: #383874;
            }
        }
        .progress-bottom-content{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            // padding-top: 8px;
            span{
                font-size: 10px;
                line-height: 14px;
                font-weight: 500;
                color: red;
            }
        }
    }
   
  }
}
