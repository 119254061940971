.infra-toplogy-view {
    height: 100%;
    .gmnoprint {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 30px;
      z-index: 1;

      .gmnoprint-plus-minus {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);

        .btn {
          display: block;
          color: #023aff;
          font-size: 14px;
          line-height: 30px;
          width: 30px;
          height: 30px;
          border: none;
          cursor: pointer;
          padding: 0;
          text-align: center;
          background-color: #ffffff;
        }
      }

      .gmnoprint-map {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);

        .btn {
          display: block;
          color: #023aff;
          font-size: 14px;
          line-height: 30px;
          width: 30px;
          height: 30px;
          border: none;
          padding: 0;
          text-align: center;
          background-color: #ffffff;
        }
      }
    }
    .services-text-box {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      position: relative;
      // margin-right: 80px;
      vertical-align: text-top;
      width: 140px;
      height: 55px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: #ffffff;
      background: #384cff;
      border-radius: 10px;
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
      &.active {
        border-color: #00861b;
      }
      .account-image {
        padding: 4px;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
      }
      .account-id {
        width: 80px;
        span {
          width: 80px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .global-servies {
      display: inline-flex;
      width: 150px;
      flex-wrap: wrap;
      margin-right: 0px;

      &.cluster-servies {
        width: 26%;
        &::after {
          top: auto;
          bottom: 45px;
        }
      }

      &:last-child {
        margin-right: 0;

        &::after {
          display: none;
        }
      }

      .global-servies-menu {
        margin-top: 0;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        border-radius: 10px;
        overflow: hidden;
        background-color: #ffffff;
        border: 1px solid #a5a5d7;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);

        label {
          display: flex;
          justify-content: center;
          text-align: center;
          flex-wrap: wrap;
          width: 100%;
          height: auto;
          padding-top: 7px;
          padding-bottom: 7px;
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          color: #383874;
          cursor: pointer;
          border-bottom: 1px solid #a5a5d7;

          &:last-child {
            border: none;
          }

          &.active {
            color: #ffffff;
            background-color: #416bff;
          }

          span {
            display: inline-block;
            width: 12px;
            margin-right: 5px;
            margin-top: -2px;

            img {
              max-width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 15px;
        list-style: none;
        position: relative;
        border-radius: 10px;
        border: 1px solid #a5a5d7;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);

        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 30px;
          font-size: 12px;
          line-height: 30px;
          font-weight: 600;
          color: #383874;
          padding-left: 12px;
          cursor: pointer;
          position: relative;
          border-bottom: 1px solid #a5a5d7;
          p {
            width: calc(100% - 25px);
            padding: 0;
            margin: 0;
            color: #383874;
            font-size: 12px;
            line-height: 30px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:first-child {
            border-top-left-radius: 9px;
            border-top-right-radius: 9px;
          }

          &:last-child {
            border-bottom: none;
            border-bottom-right-radius: 9px;
            border-bottom-left-radius: 9px;
          }

          &.active {
            color: #ffffff;
            background-color: #384cff;
            padding-left: 8px;
            p {
              color: #ffffff;
            }
            span {
              width: 20px;
              height: 20px;
              padding: 3px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #ffffff;
              border-radius: 50%;
            }
            &::after {
              opacity: 1;
            }
          }

          span {
            display: inline-flex;
            width: 12px;
            margin-right: 5px;
            // margin-top: -2px;

            img {
              max-width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }