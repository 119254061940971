.wizard-container {
  margin-right: 20px;
  .wizard-step-line-container {
    padding: 0;
    //   background-color: #f8f9fb;

    .wizard-step-button {
      display: inline-block;
      width: 20%;
      border-top: 1px solid #707070b5;
      border-bottom: 1px solid #707070b5;
      background-color: #dde4e9;
      font-size: 12px;
      line-height: 20px;
      padding: 5px 0;
      text-align: center;
      color: #414d55;
      font-weight: 500;
      cursor: pointer;
      position: relative;

      &:last-child {
        border-right: 1px solid #707070b5;
      }

      &:first-child {
        border-left: 1px solid #707070b5;
      }

      &:hover {
        background-color: #efefef;
        color: #2662f0;
      }

      &.active {
        background-color: #ffffff;
        color: #2662f0;
      }

      &:after {
        position: absolute;
        top: 1px;
        right: -27px;
        content: "";
        border: solid transparent;
        border-color: rgba(221, 228, 233, 0);
        border-left-color: #dde4e9;
        border-width: 14px;
        margin-top: 0px;
        width: 0;
        height: 0;
        z-index: 1;
      }

      &:before {
        position: absolute;
        top: 1px;
        right: -28px;
        content: "";
        border: solid transparent;
        border-color: rgba(159, 163, 165, 0);
        border-left-color: #9fa3a5;
        border-width: 14px;
        margin-top: 0px;
        width: 0;
        height: 0;
        z-index: 1;
      }

      &:hover:after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #efefef;
      }

      &.active:after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #ffffff;
      }

      &:hover:before {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #efefef;
      }

      &.active:before {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #ffffff;
      }

      &:last-child {
        &:after,
        &:before {
          display: none;
        }
      }
    }
  }
  .wizard-step-component-container {
    background: #ffffff;
    border-radius: 10px;
    height: 575px;
    padding: 20px;
    margin-top: 15px;
    .wizard-step-component {
      padding: 0;
      display: block;
      height: 94%;
      &.d-none {
        display: none;
      }
      .alert-details {
        width: 100%;
        display: block;
        .alert-details-name {
          padding-bottom: 25px;

          .input-group-text {
            background-color: #ffffff;
            border: 1px solid #8392a7;
            border-radius: 4px;
            line-height: 38px;
            height: 38px;
            padding: 0 15px;
            font-size: 12px;
            font-weight: 500;
            width: 100%;
            text-align: left;
          }
        }
        .alert-details-description {
          padding-bottom: 25px;
          label {
            display: inline-block;
            color: #3b4859;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            padding-bottom: 5px;
          }
          textarea {
            display: block;
            background-color: #ffffff;
            border: 1px solid #8392a7;
            border-radius: 4px;
            line-height: 24px;
            padding: 5px 10px;
            color: #8998ac;
            font-size: 12px;
            font-weight: 500;
            width: 100%;
            max-height: 180px;
            height: 180px;
          }

          //**************************** Alert Type ****************************//

          .time-series-box {
            background-color: #f8f9fb;
            border: 1px solid #979797;
            display: grid;
            width: 100%;

            .time-series-inner {
              overflow-x: auto;
              display: grid;
              width: 100%;
              .time-series-header {
                border-bottom: 1px solid #dcdee3;
                .heading {
                  color: #3b4859;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 20px;
                  display: inline-block;
                  border-right: 1px solid #dcdee3;
                  padding: 15px 0 15px 15px;

                  &.retention-policy {
                    width: 25%;
                  }

                  &.measurements {
                    width: 37%;
                  }

                  &.fields {
                    width: 37%;
                    border-right: none;
                  }
                }
              }
              .time-series-body {
                .database {
                  padding: 15px 0 15px 15px;
                  border-right: 1px solid #dcdee3;
                  display: inline-block;
                  width: 25%;
                  min-height: 255px;
                  height: 255px;
                  vertical-align: top;
                  overflow: auto;
                  overflow-y: auto;

                  li {
                    color: #3b4859;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    padding-bottom: 10px;
                    display: block;

                    a {
                      color: #3b4859;
                    }

                    &.active a,
                    a:hover {
                      color: #3b7cff;
                    }
                  }
                }

                .database-selected {
                  padding: 0 0 15px 0px;
                  border-right: 1px solid #dcdee3;
                  display: inline-block;
                  width: 37%;
                  min-height: 255px;
                  vertical-align: top;
                  ul {
                    li {
                      color: #3b4859;
                      font-weight: 400;
                      font-size: 13px;
                      line-height: 20px;
                      display: block;
                      position: relative;
                      padding-left: 30px;
                      padding-bottom: 5px;
                      padding-top: 5px;
                      padding-right: 15px;
                      a {
                        color: #3b4859;
                        display: inline-block;
                      }
                      &:after,
                      &:before {
                        left: 15px;
                        top: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                      }

                      &:after {
                        border-color: rgba(248, 249, 251, 0);
                        border-left-color: #f8f9fb;
                        border-width: 4px;
                        margin-top: -3px;
                      }

                      &:before {
                        border-color: rgba(131, 146, 167, 0);
                        border-left-color: #8392a7;
                        border-width: 5px;
                        margin-top: -4px;
                      }
                      &.selected {
                        background-color: #efefef;

                        &:after,
                        &:before {
                          top: 13px;
                          left: 15px;
                          border: solid transparent;
                          content: " ";
                          height: 0;
                          width: 0;
                          position: absolute;
                          pointer-events: none;
                        }

                        &:after {
                          border-color: rgba(248, 249, 251, 0);
                          border-top-color: #efefef;
                          border-width: 4px;
                          margin-left: -3px;
                          margin-top: 0;
                        }

                        &:before {
                          border-color: rgba(239, 239, 239, 0);
                          border-top-color: #8392a7;
                          border-width: 5px;
                          margin-left: -4px;
                          margin-top: 0;
                        }
                      }
                      .toggle-btn {
                        float: right;
                        min-width: 16px;
                        min-height: 16px;
                        font-size: 12px;
                        line-height: 16px;
                        border: 1px solid #707070;
                        background-color: #f8f9fb;
                        color: #414d55;
                        text-align: center;
                        padding: 5px;
                      }
                      .toggle-box {
                        display: block;
                        width: 100%;
                        padding: 10px 0 0 0;

                        ul li.selected {
                          padding-left: 15px;
                        }

                        .hostname {
                          float: right;
                          width: auto;
                          height: auto;
                          line-height: 16px;
                          background-color: #f8f9fb;
                          color: #414d55;
                          text-align: center;
                          padding: 2px 5px;
                          font-size: 12px;
                          margin-top: 1px;
                        }

                        ul li {
                          &.selected {
                            &:before,
                            &:after {
                              left: 0;
                            }
                          }

                          &:before,
                          &:after {
                            left: 0;
                          }
                        }

                        .hostname-search {
                          display: block;
                          position: relative;
                          width: 100%;
                          margin-top: 10px;

                          .input-group-text {
                            border: 1px solid #8392a7;
                            background-color: #ffffff;
                            border-radius: 4px;
                            font-size: 11px;
                            font-weight: 300;
                            padding-left: 25px;
                            color: #8998ac;
                            line-height: 20px;
                            height: 20px;
                            text-align: left;
                            width: 100%;
                          }

                          button {
                            position: absolute;
                            left: 0;
                            top: 3px;
                            border: none;
                            background-color: transparent;
                            color: #8392a7;
                            font-size: 12px;
                          }
                        }

                        .hostip {
                          display: block;
                          font-size: 11px;
                          line-height: 14px;
                          font-weight: 300;
                          color: #3b4859;
                          position: relative;
                          padding-left: 10px;
                          margin-top: 5px;

                          &:before {
                            content: "";
                            width: 6px;
                            height: 6px;
                            background-color: #2662f0;
                            border-radius: 50%;
                            position: absolute;
                            left: 0;
                            top: 3px;
                          }
                        }
                      }
                    }
                  }
                }
                .measurement-selected {
                  padding: 0 0 15px 0px;
                  display: inline-block;
                  width: 37%;
                  min-height: 255px;
                  vertical-align: top;

                  > ul {
                    list-style: none;

                    > li {
                      display: block;
                      color: #3b4859;
                      font-weight: 400;
                      font-size: 13px;
                      line-height: 20px;
                      padding: 5px 0 5px 33px;
                      position: relative;

                      > a {
                        color: #3b4859;
                      }

                      &:before {
                        background-color: #e4eaf0;
                        border: 2px solid #e4eaf0;
                        height: 12px;
                        width: 12px;
                        content: "";
                        position: absolute;
                        left: 15px;
                        top: 10px;
                        border-radius: 2px;
                      }

                      &.selected:before {
                        border: 2px solid #fdfdfe;
                        background-color: #2662f0;
                      }
                    }
                  }

                  .functions {
                    float: right;
                    width: auto;
                    padding: 2px 10px;
                    color: #3b4859;
                    font-size: 11px;
                    line-height: 14px;
                    font-weight: 300;
                    background-color: #ffffff;
                    margin-top: 3px;
                    margin-right: 30px;
                  }

                  .functions-box {
                    background-color: #ffffff;
                    margin-top: 5px;
                    margin-right: 30px;

                    ul {
                      padding: 8px 8px 0 8px;
                      li {
                        display: inline-block;
                        margin: 0 8px 8px 0;
                        vertical-align: top;
                        a {
                          display: block;
                          color: #475363;
                          background-color: #e4eaf0;
                          padding: 0 10px;
                          font-size: 11px;
                          line-height: 20px;
                          height: 20px;
                          border-radius: 3px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          //**************************** Conditions ****************************//

          .condition-box {
            background-color: #f8f9fb;
            border: 1px solid #979797;
            border-radius: 5px;
            .condition-header {
              border-bottom: 1px solid #979797;
              padding: 10px 0px 7px 15px;

              .send-alert-text {
                color: #3b4859;
                font-size: 14px;
                font-weight: 400;
                line-height: 40px;
                display: inline-block;
                padding-right: 5px;
                vertical-align: top;
                .time-series-text {
                  display: inline-block;
                  background-color: #efefef;
                  color: #2662f0;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 40px;
                  vertical-align: top;
                  padding: 0 15px 0 10px;
                }
              }

              // .greater-select {
              //   display: inline-block;
              //   padding: 0 0 0 0;
              //   select {
              //     background-color: #efefef;
              //     color: #414d55;
              //     font-size: 14px;
              //     font-weight: 400;
              //     line-height: 40px;
              //     height: 40px;
              //     padding: 0 10px;
              //     border-radius: 3px;
              //     border: none;
              //     width: 200px;
              //   }
              // }
              .greater-search {
                display: inline-block;
                padding: 0 0 0 10px;
                input {
                  border: 1px solid #8392a7;
                  border-radius: 4px;
                  background-color: #f8f9fb;
                  color: #333333;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 38px;
                  height: 38px;
                  padding: 0 10px;
                }
              }
              .mapping-fliter {
                position: relative;
                display: inline-block;

                button {
                  border: 1.5px solid #384cff;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 18px;
                  color: #023aff;

                  &:hover {
                    border: 1.5px solid #384cff;
                  }
                  svg {
                    color: #3a4dfe;
                  }
                }

                .fliter-toggel {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 180px;
                  border: none;
                  background: #dde1f8;
                  color: #383874;
                  font-size: 12px;
                  font-weight: 600;
                  height: 36px;
                  padding: 0 15px;
                  border-radius: 4px;
                  cursor: pointer;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  .arrow-icon {
                    margin-left: 20px;
                  }
                }

                .fliter-collapse {
                  background-color: #ffffff;
                  border-radius: 5px;
                  padding: 3px;
                  display: none;
                  position: absolute;
                  top: 38px;
                  width: 100%;
                  right: 0;
                  z-index: 10;
                  overflow: hidden;
                  overflow-y: auto;
                  max-height: 200px;
                  box-shadow: 0px 4px 32px 0px #00103d29;

                  &.active {
                    display: block;
                  }

                  ul {
                    display: block;
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      padding: 0;
                      margin-bottom: 0;
                      color: #383874;
                      font-size: 12px;
                      line-height: 16px;
                      font-weight: 500;
                      padding: 7px 10px;
                      border-radius: 3px;
                      cursor: pointer;

                      span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }

                      &:hover {
                        color: #383874;
                        background-color: #dde1f8;
                      }

                      &.active {
                        color: #383874;
                        background-color: #dde1f8;
                      }

                      i {
                        margin-right: 7px;
                      }
                    }
                  }
                }

                .fliters-collapse-bg {
                  position: fixed;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  z-index: 1;
                  display: none;

                  &.active {
                    display: block;
                  }
                }
              }
            }
            .alert-handler-box {
              height: 375px;
              overflow: hidden;
              .alert-left-box {
                background-color: #dde4e9;
                padding: 0px;
                height: 375px;
                overflow: hidden;
                overflow-y: auto;
                ul {
                  padding: 0;
                  li {
                    width: 100%;
                    display: block;
                    padding: 7px 15px;
                    color: #3b4859;
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    position: relative;
                    cursor: pointer;
                    i {
                      position: absolute;
                      top: 10px;
                      right: 15px;
                      color: red;
                    }

                    &:hover {
                      background-color: #f8f9fb;
                      color: #2662f0;
                    }
                    &.active {
                      background-color: #f8f9fb;
                      color: #2662f0;
                    }
                  }
                }
              }
              .tab-content {
                width: 100%;
                display: block;
                max-height: 365px;
                padding-right: 50px;
                overflow: auto;
                overflow-y: auto;
                .alert-handler-span {
                  padding-top: 20px;
                  color: #3b4859;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 18px;
                  display: block;
                  margin-bottom: 10px;
                }
                .alert-handler-label {
                  display: block;
                  color: #3b4859;
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  padding-bottom: 8px;
                }
                .form-control {
                  display: block;
                  width: 100%;
                  height: calc(1.5em + 0.75rem + 2px);
                  padding: 0.375rem 0.75rem;
                  font-size: 0.8rem;
                  font-weight: 400;
                  line-height: 1.5;
                  color: #495057;
                  background-color: #fff;
                  background-clip: padding-box;
                  border: 1px solid #ced4da;
                  border-radius: 0.25rem;
                  transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
                }
              }
            }
            .time-series-preview-box {
              display: block;
              padding: 15px 20px;
              min-height: 350px;
              height: 350px;
              .chart-select-menu {
                display: block;
                padding-bottom: 10px;
                width: 100%;
                label {
                  display: inline-block;
                  vertical-align: top;
                  font-size: 12px;
                  font-weight: 300;
                  color: #3b4859;
                  line-height: 34px;
                  padding-right: 15px;
                  padding-bottom: 0;
                }
                .select-menu {
                  display: inline-block;
                  width: auto;
                  position: relative;
                  i {
                    margin-right: 0;
                    font-size: 18px;
                    color: #414d55;
                    line-height: 34px;
                    position: absolute;
                    z-index: 1;
                    left: 10px;
                    top: 0;
                  }
                  select {
                    background-color: #efefef;
                    display: inline-block;
                    border-radius: 4px;
                    vertical-align: top;
                    font-size: 12px;
                    font-weight: 300;
                    color: #3b4859;
                    line-height: 34px;
                    padding: 0 10px 0 28px;
                    width: 120px;
                    border: none;
                  }
                }
              }
              .cq-query-text {
                display: inline-block;
                width: 2%;
                border-right: 1px solid #3b4859;
                height: 83%;
                vertical-align: top;
                span {
                  transform: rotate(-90deg);
                  display: block;
                  color: #3b4859;
                  font-size: 12px;
                  line-height: 16px;
                  font-weight: 300;
                  width: 100px;
                  text-align: center;
                  margin-left: -42px;
                  margin-top: 125px;
                }
              }
              .line-chart {
                width: 97%;
                height: 83%;
                display: inline-block;
                vertical-align: top;
                padding-left: 1%;
                padding-top: 30px;
                position: relative;
                &::before {
                  position: absolute;
                  left: 42px;
                  top: 10px;
                  height: 30px;
                  width: 95.5%;
                  content: "";
                  background-color: #3b7cff;
                }
              }
            }
            .condition-message-box {
              padding: 20px;
              padding-bottom: 50px;
              display: block;
              .templates-text {
                display: block;
                margin-top: 15px;
                max-height: 110px;
                overflow: auto;
                overflow-y: auto;

                li {
                  color: #3b4859;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 30px;
                  padding: 0 7px 7px 0;
                  display: inline-block;
                  vertical-align: top;
                  cursor: pointer;

                  pre {
                    background-color: #dde1f8;
                    color: #383874;
                    font-size: 14px;
                    border-radius: 5px;
                    line-height: 30px;
                    margin-bottom: 0;
                    font-weight: 400;
                    border: none;
                    padding: 0 7px;
                    margin-top: 0;

                    &:hover {
                      background-color: #eaedfc;
                    }
                  }
                }
              }
              .condition-message-examplex {
                textarea {
                  background-color: transparent;
                  color: #3b4859;
                  font-size: 14px;
                  line-height: 20px;
                  margin-bottom: 0;
                  font-weight: 400;
                  padding: 10px 20px;
                  border: 1px solid #979797;
                  min-height: 180px;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .wizard-step-line-container {
    .wizard-step-button {
      width: 100%;
      border-right: 1px solid #707070b5;
      border-left: 1px solid #707070b5;
      border-bottom: none;
      &:last-child {
        border-bottom: 1px solid #707070b5;
      }
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
}
