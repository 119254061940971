.application-status-dashboard-container {
  width: 100%;
  display: block;
  padding: 40px 15px 40px;
  background-color: #eef0fa;

  .heading {
    display: block;
    width: 100%;
    padding: 0 0 20px 0;

    h3 {
      display: block;
      color: #383874;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 0;

      span {
        display: block;
      }
    }

    .button-group {
      display: flex;
      justify-content: flex-end;

      .primary-outline-btn {
        padding-left: 44px;
        padding-right: 44px;
      }
    }
  }

  .application-table-section {
    padding: 0px;
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px #6c49ac0d;
    .head {
      display: block;
      width: 100%;
      padding: 15px 30px;
      span {
        display: block;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        color: #00b929b2;
        text-transform: uppercase;
      }
      strong {
        display: block;
        font-size: 13px;
        font-weight: 700;
        line-height: 16px;
        color: #000000;
        margin-top: 5px;
        text-transform: uppercase;
      }
      .search-box {
        .search-control-group {
          .input-group-text {
            font-size: 12px;
            height: 26px;
            border: none;
            padding-left: 15px;
            padding-right: 45px;
            border-radius: 7px;
            background-color: #eef0fa;
          }
          button {
            left: auto;
            right: 0;
            height: 26px;
            width: 26px;
            font-size: 11px;
          }
        }
      }
    }
    .application-table {
      tr {
        th {
          border-top: 2px solid #dbdff1;
          border-bottom: 2px solid #dbdff1;
          border-right: 2px solid #dbdff1;
          padding: 10px 7px;
          font-size: 13px;

          &:last-child {
            border-right: none;
          }
        }
        td {
          border-top: none;
          border-bottom: none;
          border-right: 2px solid #dbdff1;
          padding: 7px 7px;
          font-size: 13px;

          &:last-child {
            border-right: none;
          }

          .done {
            display: inline-block;
            color: #383874;
            font-size: 13px;
            line-height: 16px;
            font-weight: 600;
            padding: 5px 10px 5px 20px;
            border-radius: 7px;
            position: relative;
            background-color: #dbdff1;
            &::after {
              position: absolute;
              left: 10px;
              top: 10px;
              width: 6px;
              height: 6px;
              content: "";
              border-radius: 50%;
              background-color: #53ca43;
            }
          }
        }
        &:first-child {
          td {
            padding-top: 30px;
          }
        }
        &:last-child {
          td {
            padding-bottom: 30px;
          }
        }
      }
    }
  }
}

