.super-admin-container {
  width: 100%;
  display: block;
  padding: 30px 45px;
  .overview-section {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    .overview-card {
      padding: 25px;
      border-radius: 10px;
      background: #ffffff;
      h5 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #383874;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #383874;
        max-height: 150px;
        overflow: hidden;
        overflow-y: auto;
      }
    }

    .overview-buttons {
      display: flex;
      height: 100%;
      align-items: flex-end;
      justify-content: flex-end;
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          list-style: none;
          padding: 0;
          margin-right: 15px;
          width: auto;
          &:last-child {
            margin-right: 0;
          }
          .primary-btn {
            a {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .tabs {
    display: block;
    width: 100%;

    .tabs-menu {
      width: 100%;
      padding-bottom: 0px;
      padding-top: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #c6cbd9;

      li {
        padding-bottom: 16px;
        margin-right: 10px;
        margin-left: 10px;
        font-size: 13px;
        font-weight: 600;
        color: #383874;
        cursor: pointer;
        width: auto;
        display: inline-flex;
        position: relative;

        &::after {
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
          content: "";
          opacity: 0;
          position: absolute;
          background-color: #8676ff;
          border-top-left-radius: 50px;
          border-top-right-radius: 50px;
          box-shadow: rgba(134, 118, 255, 0.15) 0px 2px 1px,
            rgba(134, 118, 255, 0.15) 0px 4px 2px,
            rgba(134, 118, 255, 0.15) 0px 8px 4px,
            rgba(134, 118, 255, 0.15) 0px 16px 8px,
            rgba(134, 118, 255, 0.15) 0px 32px 16px;

          //box-shadow: 0px 100px 80px 0px #0f59ff5c;
        }

        &.active {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    .tabs-content {
      padding: 10px 0 0 0;
      background-color: #f6f7fb;
      .access-control-table {
        .roles-box {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          background: #00b929;
          display: inline-block;
          padding: 4px 10px;
          border-radius: 4px;
          margin-left: 10px;
          color: #ffffff;

          span {
            display: flex;
            align-items: center;
          }

          img {
            margin-right: 5px;
            height: 100%;
          }
        }
      }
      .allowed-permission-table {
        table {
          width: 100%;
          thead {
            tr {
              th {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 15px;
                padding-right: 15px;
                font-size: 14px;
                line-height: 25px;
                font-weight: 500;
                width: 350px;
                white-space: nowrap;
              }
            }
          }
          tbody {
            display: table-row-group;
            tr {
              &.active {
                background-color: #e9edf5;
              }
              td {
                padding-left: 15px;
                padding-right: 15px;
                width: 350px;
                white-space: nowrap;
                color: #171c26;
                button {
                  width: 20px;
                  height: 20px;
                  border: 1px solid #414d55;
                  .MuiSvgIcon-root {
                    width: 0.8rem;
                    height: 0.8rem;
                  }
                }
                .check-icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  background: #00b929;
                  color: #ffffff;
                }
              }
              .inner-table-section {
                padding: 0;
                .MuiCollapse-wrapper {
                  .MuiBox-root {
                    margin: 0;
                    table {
                      tbody {
                        tr {
                          &:hover {
                            background-color: #f7f9fc;
                            td {
                              &:first-child {
                                padding-left: 25px;
                              }
                            }
                          }
                          td {
                            color: #171c26;
                            font-size: 12px;
                            font-weight: 500;
                            padding-left: 15px;
                            padding-right: 15px;
                            width: 350px;
                            white-space: nowrap;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .roles-box {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          background: #00b929;
          display: inline-block;
          padding: 4px 10px;
          border-radius: 4px;
          color: #ffffff;

          span {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
