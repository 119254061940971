.setting-modal-container {
  width: 380px !important;
  box-shadow: 0px 0px 16px rgba(59, 72, 89, 0.2392156863);
  border-radius: 10px;
  background: white;
  margin: 0 auto;

  .modal-content {
    padding: 30px 30px;

    .modal-header {
      margin-bottom: 20px;
      border: block;
      padding: 0 15px 8px;
      margin: 0 -15px 10px;

      .modal-title {
        text-align: left !important;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;

        h5 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          color: #344054;

          .close-btn {
            margin-left: auto;
            cursor: pointer;
          }
        }

        p {
          display: flex;
          width: 100%;
          font-size: 11px;
          color: #344054;
        }
      }
    }

    .modal-body {
      .form-group {
        width: 100%;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 15px;

        .form-label {
          display: flex;
          width: 100%;
          font-size: 14px;
          color: #344054;
          line-height: 20px;
          font-weight: 500;
          margin-bottom: 5px;
          padding-left: 0;
        }

        .form-control {
          display: flex;
          width: 100%;
          height: 36px;
          padding: 5px 15px 5px 15px;
          border: 1px solid #d0d5dd;
          background: linear-gradient(0deg, #ffffff, #ffffff);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          border-radius: 5px;
          font-size: 12px;
          line-height: 26px;
          font-weight: 400;
          color: #344054;
          font-family: "Poppins", sans-serif;

          &:focus-visible {
            outline: none;
          }
        }

        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          margin-top: 5px;
        }

        .select-policy {
          width: 100%;

          .MuiOutlinedInput-root {
            width: 100%;
            height: 40px;
            line-height: 40px;

            .MuiSelect-select {
              width: 86%;
              display: block;
              font-size: 13px;
              line-height: 40px;
              height: 36px;
              font-weight: 400;
              border-color: #d0d5dd;
              padding: 0 15px;
              font-style: normal;

              .MuiSelect-nativeInput {
                height: 36px;
              }

              em {
                font-style: normal;
              }

              &:focus-visible {
                outline-color: transparent;
              }

              &:hover {
                border-color: #d0d5dd;
              }
            }

            .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
              top: calc(54% - 0.5em);
            }
          }
        }

        .input-group-text {
          position: absolute;
          top: 30px;
          right: 15px;
          cursor: pointer;

          i {
            color: #dadada;
            font-size: 14px;
          }

          &.rotate {
            right: 45px;

            i {
              color: #8676ff;
            }

            &:hover {
              i {
                color: #384cff;
              }
            }
          }

          &:hover {
            i {
              color: #344054;
            }
          }
        }
      }

      .password-strength-group {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;

        span {
          width: 25%;
          height: 6px;
          margin-left: 2px;
          margin-right: 2px;
          display: inline-flex;
          background: rgba(198, 203, 217, 1);
          border-radius: 5px;

          &.good {
            background: rgba(250, 162, 75, 1);
          }

          &.strong {
            background-color: rgba(0, 85, 0, 1);
          }
        }
      }

      p {
        font-size: 11px;
        line-height: 18px;
        font-weight: 500;
        color: rgba(102, 112, 133, 1);
      }

      .scanner-image {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;

        img {
          max-width: 150px;
        }
      }

      .list-contents {
        padding-bottom: 20px;
        width: 86%;
        margin: 0 auto;

        li {
          display: block;
          padding-left: 12px;
          padding-right: 0;
          padding-top: 2px;
          padding-bottom: 2px;
          font-size: 11px;
          font-weight: 400;
          align-items: flex-start;
          color: rgba(102, 112, 133, 1);

          strong {
            font-weight: 600;
          }

          a {
            color: #384cff;
            font-weight: 600;
          }
        }
      }

      .digit-code {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        padding-bottom: 40px;

        .digit-input {
          display: flex;
          margin-top: 10px;
          margin-bottom: 20px;

          .form-control {
            width: 34px !important;
            padding-left: 5px;
            padding-right: 5px;
            margin-left: 2px;
            margin-right: 2px;
            text-align: center;
            border: 1px solid rgba(27, 77, 255, 0.93);
          }
        }
      }
    }

    .modal-footer {
      padding-top: 10px;
      padding-bottom: 0;
    }
  }

  &.permissions-modal {
    .modal-content {
      padding: 15px;

      .modal-footer {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.add-permition-modal {
    width: 380px;

    .modal-content {
      .modal-header {
        margin-bottom: 0;
        padding-bottom: 10px;
        border-bottom: 1px dashed #000000;
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 0;
        padding-bottom: 10px;
        border-bottom: 1px dashed #000000;

        .modal-title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h5 {
            display: inline-block;
            width: auto;
          }
        }
      }

      .modal-body {
        padding: 15px 0 20px 0;

        h5 {
          margin-bottom: 20px;
        }

        .form-group {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .form-label {
            display: inline-block;
            width: auto;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.delete-policy-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .leave-heading {
      color: #3772ff;
    }

    .delete-icon {
      margin: 0 auto 15px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: #ffebeb;
      color: #e53535;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 40px;
      }
    }

    .cancel-icon {
      margin: 15px auto;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: #ebeff7;
      color: #3772ff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .modal-footer {
      padding-top: 10px;
      padding: 0;

      .danger-btn {
        width: 150px;
      }

      .secondary-btn {
        width: 150px;
      }

      .primary-btn {
        width: 150px;
      }
    }
  }
}

.invite-user-modal-container {
  width: 600px !important;
  box-shadow: 0px 0px 16px rgba(59, 72, 89, 0.2392156863);
  border-radius: 10px;
  background: white;
  margin: 0 auto;
  height: 650px;

  .modal-content {
    padding: 25px;
    height: 100%;

    .modal-header {
      margin-bottom: 15px;
      padding-bottom: 15px;
      margin-left: -25px;
      margin-right: -25px;
      padding-left: 25px;
      padding-right: 25px;

      .modal-title {
        text-align: center;
        width: 100%;

        h5 {
          width: 100%;
          color: #344054;
        }
      }
    }

    .modal-body {
      width: 100%;
      height: 100%;

      .stepar-content {
        width: 100%;
        height: 90%;
        display: block;

        .steper-label {
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          color: #383874;
          text-align: center;
          margin-bottom: 5px;
        }

        .steper-button {
          padding: 0;
          width: 150px;
          .css-vnkopk-MuiStepLabel-iconContainer {
            padding-right: 0;
          }

          .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
            color: #00b929;
          }

          .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
            color: #00b929;
          }
          .css-8je8zh-MuiTouchRipple-root {
            width: 0;
          }
        }

        .css-j5w0w9-MuiStepConnector-root {
          margin-top: 25px;

          .css-z7uhs0-MuiStepConnector-line {
            display: block;
            border-color: #bdbdbd;
            border-top-style: solid;
            border-top-width: 2px;
            margin-right: -55px;
            margin-left: -55px;
          }

          &.Mui-completed {
            .css-z7uhs0-MuiStepConnector-line {
              border-color: #00b929;
            }
          }

          &.Mui-active {
            .css-z7uhs0-MuiStepConnector-line {
              border-color: #00b929;
            }
          }
        }
      }

      .users-content {
        .step-frist {
          max-height: 365px;
          overflow: hidden;
          overflow-y: auto;

          .form-row {
            display: flex;
            flex-wrap: wrap;
          }
        }

        .step-third {
          .step-third-form {
            max-height: 140px;
            overflow: hidden;
            overflow-y: auto;
          }

          .create-user-control-table {
            .table {
              tbody {
                max-height: 170px;
              }
            }
          }
        }

        .form-group {
          margin-bottom: 15px;
          position: relative;

          .form-label {
            display: block;
            font-size: 14px;
            color: #344054;
            line-height: 20px;
            font-weight: 500;
            margin-bottom: 5px;
            padding-left: 0;
          }

          .form-control {
            width: 240px;
            height: 36px;
            padding: 5px 15px;
            margin-right: 15px;
            border: 1px solid #d0d5dd;
            background: linear-gradient(0deg, #ffffff, #ffffff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            border-radius: 5px;
            font-size: 13px;
            line-height: 26px;
            font-weight: 400;
            color: #344054;
            font-family: "Poppins", sans-serif;
          }

          span {
            position: absolute;
            bottom: -18px;
            left: 0;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: #ff2d2e;
            width: 95%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .close-icon {
            color: #ff2d2e;
            border: 1px solid #ff2d2e;
          }
        }

        .add-user {
          .compliance-btn {
            text-transform: capitalize;
            background-color: #dde1f8;
            color: #383874;
            font-weight: 600;
            font-size: 13px;
            line-height: 24px;
            box-shadow: none;
            text-transform: none;
            letter-spacing: 0px;
            border-radius: 5px;
          }
        }

        .user-review {
          .form-group {
            margin-bottom: 15px;

            .form-label {
              font-size: 14px;
              color: #344054;
              line-height: 20px;
              font-weight: 500;
              margin-bottom: 5px;
              padding-left: 0;
            }

            .form-control {
              width: 100%;
              height: 36px;
              margin-top: 5px;
              padding: 5px 15px;
              border: 1px solid #d0d5dd;
              background: linear-gradient(0deg, #ffffff, #ffffff);
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              border-radius: 5px;
              font-size: 13px;
              line-height: 26px;
              font-weight: 400;
              color: #344054;
              font-family: "Poppins", sans-serif;
            }
          }
        }

        .create-user-control-table {
          display: block;
          background-color: #ffffff;
          padding: 0;
          border-radius: 10px;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);

          .table {
            display: block;
            width: 100%;

            thead {
              padding-top: 0px;
              padding-bottom: 0px;
              display: table;
              width: calc(100% - 0.1em);

              &.active {
                th {
                  border-bottom: 1px dashed #b3b3b3;
                }
              }

              tr {
                display: inline-table;
                width: 100%;
                table-layout: fixed;

                th {
                  padding-top: 15px;
                  padding-bottom: 15px;
                  padding-left: 15px;
                  padding-right: 15px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #000000;
                  border: none;
                  font-family: "Poppins", sans-serif;
                  border-bottom: 1px dashed #b3b3b3;

                  strong {
                    color: #383874;
                    font-weight: 600;
                    font-size: 15px;
                    text-transform: uppercase;
                  }

                  i {
                    cursor: pointer;
                    color: #383874;
                  }

                  &.ou {
                    text-align: left;
                  }

                  &:first-child {
                    width: 200px;
                  }
                }
              }
            }

            tbody {
              display: block;
              max-height: 280px;
              overflow: auto;
              width: calc(100% - 0.05em);

              tr {
                display: table;
                width: 100%;
                table-layout: fixed;

                &:last-child {
                  border-bottom: none;

                  td {
                    border: none;

                    .menu-list {
                      top: auto;
                      bottom: 40px;
                    }
                  }
                }

                td {
                  padding: 5px 15px;
                  font-size: 13px;
                  font-weight: 500;
                  color: #101828;
                  position: relative;
                  border-bottom: 1px solid #eaecf0;
                  font-family: "Poppins", sans-serif;

                  .availability-box {
                    display: inline-block;
                    cursor: pointer;

                    span {
                      display: inline-block;
                      border-radius: 50%;

                      vertical-align: middle;
                      width: 20px;
                      height: 20px;

                      &.green {
                        background-color: #00b929;
                      }

                      &.orange {
                        background-color: #ffba69;
                      }
                    }
                  }

                  &:last-child {
                    overflow: unset;
                    text-overflow: unset;
                  }

                  &:first-child {
                    width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  &.ou {
                    max-width: 125px;
                    text-align: left;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .input-name {
                    padding-left: 18px;
                    color: #393975;
                    font-size: 15px;
                  }

                  i {
                    vertical-align: baseline;
                  }

                  .list-icon {
                    background: transparent;
                    width: 30px;
                    height: 30px;
                    border: none;
                    color: #1b4dff;
                    position: relative;
                    cursor: pointer;
                    border-radius: 3px;
                    font-size: 16px;
                    line-height: 30px;

                    &:hover {
                      background: #e5e1ff;
                      color: #1b4dff;
                    }
                  }

                  .menu-list {
                    position: absolute;
                    width: 160px;
                    top: 35px;
                    padding: 5px;
                    z-index: 12;
                    right: 47%;
                    border-radius: 5px;
                    background: #ffffff;
                    box-shadow: -1px 2px 10px 5px rgba(0, 0, 0, 0.05);

                    ul {
                      display: block;
                      width: 100%;
                      padding: 0;
                      margin: 0;

                      li {
                        display: block;
                        list-style: none;
                        text-align: left;
                        border-radius: 3px;
                        padding: 5px 10px;
                        font-size: 10px;
                        line-height: 14px;
                        font-weight: 600;
                        color: #383874;

                        &.active {
                          background: #dde1f8;
                        }

                        a {
                          font-size: 10px;
                          line-height: 14px;
                          font-weight: 600;
                          color: #383874;
                        }
                      }
                    }
                  }

                  a {
                    font-weight: 600;
                    color: #384cff;
                  }

                  &:nth-child(1) {
                    text-align: left;
                  }

                  .green-btn {
                    color: #ffffff;
                    background: #00b929;
                    font-size: 12px;
                    font-weight: 500;
                    border: none;
                    padding: 5px 10px;
                    border-radius: 4px;
                  }

                  .billing-section {
                    min-height: 250px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .billing-details {
                      width: 80%;
                      height: 40px;
                      padding: 10px;
                      background-color: #023aff;
                      display: flex;
                      border-radius: 5px;
                      align-items: center;
                      justify-content: flex-start;

                      i {
                        font-size: 18px;
                        color: #01f1e3;
                        margin-right: 10px;
                      }

                      p {
                        color: #ffffff;
                        margin: 0;
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 500;
                      }
                    }
                  }

                  .compliance-btn {
                    text-transform: capitalize;
                    background-color: #dde1f8;
                    color: #383874;
                    font-size: 13px;
                    line-height: 16px;
                    padding: 5px 15px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }

    .modal-footer {
      padding-top: 10px;
      padding-bottom: 0;
    }
  }
}

.add-policy-modal-container {
  width: 800px !important;
  box-shadow: 0px 0px 16px rgba(59, 72, 89, 0.2392156863);
  border-radius: 10px;
  background: white;
  margin: 0 auto;

  .modal-content {
    padding: 20px 30px 30px 30px;

    .modal-header {
      padding-bottom: 15px;
      // border: none;
      padding-right: 30px;
      padding-left: 30px;
      margin-left: -30px;
      margin-right: -30px;

      .modal-title {
        text-align: left !important;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;

        h5 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          color: #344054;

          .close-btn {
            margin-left: auto;
            cursor: pointer;
          }
        }
      }
    }

    .policy-boxs {
      display: block;
      width: 100%;
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
      padding: 0 5px;

      .policy-box {
        width: 100%;
        height: 280px;
        border-radius: 10px;
        padding: 8px 12px 12px 12px;
        border: 1px solid #e2e2ea;
        margin-bottom: 15px;

        .head {
          margin-left: -10px;
          margin-right: -10px;
          padding-left: 10px;
          padding-right: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px dashed #b3b3b3;
          padding-bottom: 8px;

          .title {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: #383874;
          }

          span {
            padding: 5px;
          }
        }

        .policy-list-content {
          width: 100%;
          padding-top: 10px;
          height: 85%;

          .title {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #383874;
          }

          .group-loader {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            h5 {
              font-size: 14px;
            }
          }

          ul {
            padding: 5px 0 0 20px;
            margin: 0;
            max-height: 180px;
            overflow: hidden;
            overflow-y: auto;

            li {
              font-size: 12px;
              line-height: 16px;
              font-weight: 500;
              color: #383874;
              list-style: disc;
              padding: 0;
              margin-bottom: 8px;
              display: list-item;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

.authDisabled-modal-container {
  width: 380px !important;
  box-shadow: 0px 0px 16px rgba(59, 72, 89, 0.2392156863);
  border-radius: 10px;
  background: white;
  margin: 0 auto;

  .modal-content {
    padding: 30px;

    .modal-header {
      width: 100%;
      display: block;
      border-bottom: none;

      .autodisabled-info {
        display: flex;
        justify-content: center;

        .icon {
          width: 58px;
          height: 58px;
          border-radius: 100%;
          background-color: #ffebeb;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      .heading {
        color: #344054;
        font-weight: 500;
        font-size: 17px;
        line-height: 26px;
        margin-top: 12px;
        text-align: center;
      }
    }

    .modal-body {
      .form-group {
        position: relative;
        margin: 12px 0px;

        .form-label {
          display: flex;
          width: 100%;
          font-size: 14px;
          color: #344054;
          line-height: 20px;
          font-weight: 500;
          margin-bottom: 5px;
          padding-left: 0;
        }

        .form-control {
          display: flex;
          width: 100%;
          height: 36px;
          padding: 5px 15px 5px 15px;
          border: 1px solid #d0d5dd;
          background: linear-gradient(0deg, #ffffff, #ffffff);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          border-radius: 5px;
          font-size: 12px;
          line-height: 26px;
          font-weight: 400;
          color: #344054;
          font-family: "Poppins", sans-serif;
        }

        .input-group-text {
          position: absolute;
          top: 30px;
          right: 15px;
          cursor: pointer;
        }
      }
    }
  }
}

.new-user-request-modal-container {
  width: 600px !important;
  box-shadow: 0px 0px 16px rgba(59, 72, 89, 0.2392156863);
  border-radius: 10px;
  background: white;
  margin: 0 auto;
  max-height: 600px;

  .modal-content {
    padding: 25px;
    height: 100%;

    .modal-header {
      margin-bottom: 15px;
      padding-bottom: 15px;
      margin-left: -25px;
      margin-right: -25px;
      padding-left: 25px;
      padding-right: 25px;

      .modal-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
          width: 100%;
          color: #344054;
        }
      }
    }

    .modal-body {
      width: 100%;
      height: 100%;
      .user-list {
        width: 100%;
        display: block;
        max-height: 150px;
        overflow: hidden;
        overflow-y: auto;
        padding-right: 8px;
        margin-bottom: 10px;
        ul {
          padding: 0;
          margin: 0;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            margin-bottom: 20px;
            .user-details {
              span {
                width: 35px;
                height: 35px;
                min-width: 35px;
                border-radius: 100%;
                background: #8676ff;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #ffffff;
              }
              .user-mail {
                font-size: 13px;
                line-height: 20px;
                font-weight: 400;
                color: #383874;
                strong {
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
      h4 {
        color: #383874;
      }
      .new-user-request-table {
        width: 100%;
        display: block;
        table {
          display: block;
          width: 100%;
          thead {
            padding-top: 0px;
            padding-bottom: 0px;
            display: table;
            width: calc(100% - 0.1em);
            tr {
              display: inline-table;
              width: 100%;
              table-layout: fixed;
              th {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #383874;
                border: none;
                padding: 12px 0;
                &:first-child {
                  width: 400px;
                }
              }
            }
          }
          tbody {
            display: block;
            max-height: 250px;
            overflow: auto;
            width: calc(100% - 0.05em);
            tr {
              display: table;
              width: 100%;
              table-layout: fixed;
              td {
                padding: 10px 0;
                font-size: 13px;
                line-height: 17px;
                font-weight: 500;
                width: auto;
                white-space: nowrap;
                color: #383874;
                border: none;
                span {
                  width: 35px;
                  height: 35px;
                  min-width: 35px;
                  border-radius: 100%;
                  background: #8676ff;
                  margin-right: 10px;
                  text-align: center;
                  line-height: 35px;
                  font-size: 14px;
                  color: #ffffff;
                }
                &:first-child {
                  width: 400px;
                }
              }
            }
          }
        }
      }
    }
  }
}
