.set-transitions-container {
  width: 100%;
  display: block;
  padding: 30px 45px;
  .search-bar {
    .top-search {
      position: relative;
      .form-control {
        width: 100%;
        height: 40px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin-top: 10px;
        padding: 10px 50px 10px 20px;
        margin-bottom: 10px;
        border: 1px solid #cfcfcf;
        background-color: transparent;
        border-radius: 20px;
      }
      button {
        position: absolute;
        top: 8px;
        right: 15px;
        display: inline-flex;
        border: none;
        background-color: transparent;
        color: #1B4DFF;
      }
    }
  }
  .policy-section {
    h3 {
      display: block;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      color: #383874;
    }
    .policy-permission {
      width: 100%;
      display: block;
      margin-top: 20px;
      .policy-permission-head {
        border-radius: 7px;
        padding: 20px 30px;
        background-color: #ffffff;
        .title {
          display: block;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: #383874;
        }
      }
      .policy-permission-content {
        border-radius: 7px;
        padding: 15px 30px;
        background-color: #ffffff;
        margin-top: 20px;
        cursor: pointer;
        .policy-text {
          span {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: #383874;
            margin-left: 5px;
          }
        }
        .edit-policy {
          display: flex;
          align-items: center;
          justify-content: space-between;
          ul {
            display: block;
            padding: 0;
            margin: 0;
            li {
              display: inline;
              padding: 0;
              margin: 0;
              list-style: none;
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: #383874;
              margin-right: 0px;
              button {
                text-transform: capitalize;
                color: #383874;
              }
            }
          }
          span {
            color: #383874;
          }
        }
      }
    }
  }
}
