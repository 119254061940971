.environment-container {
  &.cluster-container {
    .list-heading {
      margin-bottom: 0;
    }

    .tier-buttons {
      width: auto;
      margin-bottom: 20px;
      margin-top: 10px;
      button {
        border-width: 1px;
        border-style: solid;
        border-color: #384cff;
        padding-top: 5px;
        padding-bottom: 5px;
        &:first-child {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          border-right: none;
        }
        &:last-child {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: none;
        }
      }
     
    }

    .cluster-table {
      margin-top: 20px;
      display: block;
      width: 100%;
      .list-icon {
        background: transparent;
        width: 30px;
        height: 30px;
        border: none;
        color: #1b4dff;
        position: relative;
        cursor: pointer;
        border-radius: 3px;
        font-size: 16px;
        line-height: 30px;
        &:hover {
          background: #e5e1ff;
          color: #1b4dff;
        }
       
      }
      button:disabled {
        border: none;
        cursor: default;
        border-radius: 3px;
        font-size: 16px;
        line-height: 30px;
        width: 30px;
        height: 30px;
        color: rgba(0, 0, 0, 0.26);
        box-shadow: none;
        background-color: transparent;
        &:hover{
          background-color: transparent;
          color: rgba(0, 0, 0, 0.26);
        }
    }

      .sle-box {
        display: inline-block;
        vertical-align: top;
        position: relative;

        span {
          cursor: pointer;
          display: block;
          color: #00b929;
          font-size: 12px;
          line-height: 24px;
          font-weight: 400;
        }
      }

      .medium {
        color: #ffab2d;
      }
      .high {
        color: #00b929;
      }
      .low {
        color: #ff2d2e;
      }
    }
  }
}
