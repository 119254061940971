.services-panel-tabs {
  display: block;
  width: 100%;
  .tabs-head {
    margin-top: 25px;
    margin-bottom: 25px;
    ul {
      display: inline-flex;
      width: auto;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 30px;
      background-color: #dbdff1;
      li {
        width: auto;
        display: inline-block;
        vertical-align: top;
        font-size: 11px;
        line-height: 20px;
        font-weight: 500;
        color: #000000;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 30px;
        cursor: pointer;
        &.active {
          color: #ffffff;
          background-color: #3247e5;
        }
      }
    }
  }
 
  .tabs-content {
    display: block;
    width: 100%;
  }
}
@media only screen and (max-width: 1280px) {
  .services-panel-tabs {
    .tabs-head {
      ul {
        li {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      .tabs-break-width {
        display: block;
        text-align: left;
      }
      .tabs-menu {
        display: inline-flex!important;
        > div {
          padding: 0;
          font-size: 11px;
          line-height: 28px;
          padding-left: 15px;
          padding-right: 25px;
          color: #384cff;
        }
        > svg {
          right: 2px !important;
          color: #384cff;
        }
        > fieldset {
          border-color: #384cff;
        }
      }
    }
  }
}
