.discovered-assets-container {
  .discovered-assets-inner-tabs {
    width: 100%;
    display: block;
    .assets-sevices-tabs {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0;
      .tabs-content {
        width: 100%;
        display: block; 
        margin-top: 15px;
      }
    }
  }
}
