.process-central-container {
    margin: 50px 0 0 0;
    width: 100%;
    display: block;

    .report-inner-container {
      display: block;
      width: 100%;
    }

    .main-collapse-expand {
      display: block;
      width: 100%;

      .collapse-expand {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: 405px;
        flex-wrap: wrap;
        background: #ffffff;
        border-radius: 10px;
        margin-bottom: 10px;
        // margin-left: 1.2%;
        // margin-right: 1.2%;
        box-shadow: rgba(190, 190, 190, 0.5) 1px 1px 5px;

        .heading {
          display: block;
          width: 100%;
          text-align: left;
          padding: 15px 15px 10px 15px;

          h3 {
            font-size: 16px;
            line-height: 24px;
            color: #383874;
            font-weight: 600;
            display: block;
            margin: 0;
            letter-spacing: 0.5px;
          }

          // i {
          //   display: inline-block;
          //   font-size: 16px;
          //   line-height: 24px;
          //   color: #707070;
          //   margin-right: 15px;
          // }
        }

        .contents {
          display: block;
          width: 100%;
          padding: 0 30px;

          .tabs {
            margin: 0;
            padding: 0;
            padding-bottom: 5px;
            margin-bottom: 10px;
            list-style: none;
            display: block;
            border-bottom: 1px solid #e3e5ec;

            li {
              width: 32.33%;
              margin-left: 0.5%;
              margin-right: 0.5%;
              display: inline-block;
              font-size: 14px;
              font-weight: 600;
              color: #383874;
              text-align: center;
              line-height: 20px;
              padding: 10px 0px;
              cursor: pointer;
              position: relative;

              &.active {
                &::after {
                  content: "";
                  top: 41px;
                  left: 0;
                  position: absolute;
                  background: #8676ff;
                  height: 4px;
                  border-radius: 5px 5px 0 0;
                  width: 100%;
                  display: block;
                  box-shadow: rgba(134, 118, 255, 0.15) 0px 2px 1px,
                    rgba(134, 118, 255, 0.15) 0px 4px 2px,
                    rgba(134, 118, 255, 0.15) 0px 8px 4px,
                    rgba(134, 118, 255, 0.15) 0px 16px 8px,
                    rgba(134, 118, 255, 0.15) 0px 32px 16px;
                }
              }
            }
          }

          .reports-boxes {
            display: block;
            width: 100%;
            overflow: hidden;
            overflow-y: auto;
            height: 260px;

            .report-box {
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: space-between;
              padding: 5px 10px;
              border-bottom: 1px solid #ebebeb;

              strong {
                font-size: 12px;
                line-height: 24px;
                color: #6a6a97;
                font-weight: 400;
                display: inline-block;
                vertical-align: top;
                width: auto;
              }

              .report {
                width: auto;
                float: right;
                padding: 0px 15px 0px 0px;

                span {
                  font-size: 12px;
                  line-height: 24px;
                  color: #6a6a97;
                  font-weight: 500;
                  display: inline-block;
                  text-align: center;
                  width: 40px;

                  &.down {
                    color: #faa24b;
                  }

                  &.up {
                    color: #53ca43;
                  }
                }
              }
            }
          }
        }
      }
    }
  }


@media only screen and (max-width: 1280px){
  .process-central-container {
    .main-collapse-expand {
      .collapse-expand {
        .contents {
          display: block;
          width: 100%;
          padding: 0 20px;
          .reports-boxes {
            .report-box {
              strong {
                font-size: 12px;
                line-height: 24px;
                color: #6a6a97;
                font-weight: 400;
                display: inline-block;
                vertical-align: top;
                width: auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .report {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
 