.environment-container {
  .procurify-cards {
    width: 100%;
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;

    .procurify-card {
      display: inline-flex;
      align-items: flex-start;
      justify-content: flex-start;
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
      padding: 15px 10px;
      cursor: pointer;
      min-height: 78px;
      border-radius: 5px;
      width: 193px;
      margin-right: 15px;
      background: #ffffff;
      position: relative;

      .image {
        display: inline-flex;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: #f6f7fb;
        align-items: center;
        justify-content: center;

        img {
          max-width: 60%;
        }
      }

      .content {
        display: inline-flex;
        width: 110px;
        flex-wrap: wrap;
        margin-left: 10px;

        label {
          display: flex;
          width: 100%;
          font-size: 13px;
          line-height: 20px;
          font-weight: 500;
          color: #383874;
          margin-bottom: 3px;
        }

        strong {
          display: flex;
          width: 100%;
          font-size: 13px;
          line-height: 20px;
          font-weight: 600;
          color: #383874;
        }
      }
    }

    .procurify-card-buttons {
      display: flex;
      align-items: center;
      min-height: 78px;

      .primary-btn {
        background-color: #ffffff;
        margin-left: 10px;
        margin-right: 10px;
        padding: 10px 15px;
        font-size: 18px;

        .fa-trash-can {
          color: #ff2d2e;
        }

        .fa-rotate-right {
          color: #9a9aaf;
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .environment-container {
    .procurify-cards {
      .procurify-card {
        margin-bottom: 15px;
      }
    }
  }
}
