.set-policy-container {
  width: 100%;
  display: block;
  padding: 30px 45px;
  .overview-section {
    width: 100%;
    display: block;
    .overview-card {
      padding: 25px;
      border-radius: 10px;
      background: #ffffff;
      h5 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #383874;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #383874;
      }
    }
  }

  .policy-section {
    .policy-permission {
      width: 100%;
      display: block;
      .policy-permission-head {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 15px 20px;
        background-color: #ffffff;
        height: 60px;
        margin-bottom: 20px;
        .title {
          display: block;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: #383874;
        }
      }
    }
  }
}
