//*************************************Search Bar***************************************//
.search-box {
  .search-control-group {
    position: relative;

    // width: 250px;
    .input-group-text {
      max-width: 100%;
      text-align: left;
      padding-left: 35px;
      width: 100%;
      border: 1px solid #8392a7;
      background-color: #ffffff;
      font-size: 13px;
      color: #383874;
      font-weight: 400;
      height: 36px;
      border-radius: 3px;
      font-family: "Poppins", sans-serif;

      &::placeholder {
        color: #8998ac;
      }
    }
    button {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      cursor: pointer;
      border: none;
      background-color: transparent;
      width: 36px;
      height: 36px;
      color: #8998ac;
    }
  }
}

.collection-search {
  .float-right {
    display: flex;
  }
}

.sort-checkbox {
  display: inline-block;
  vertical-align: middle;
  padding-right: 15px;
  // padding-top: 10px;
  margin-right: 10px;

  span {
    padding-left: 10px;
    color: #3b4859;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    vertical-align: top;
  }
}

.sort-select-menu {
  background-color: #f0f3f7;
  border-radius: 8px;
  height: 40px;
  display: inline-block;
  width: 265px;

  span {
    display: inline-block;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 10px;
    vertical-align: top;
  }

  select {
    display: inline-block;
    background-color: transparent;
    border: none;
    vertical-align: top;
    margin-top: 5px;
    width: 210px;
    cursor: pointer;
    color: #464c54;
  }
}

//*********************************** fliter *********************************//

.fliter {
  position: relative;

  .fliter-toggel {
    background: #f0f3f7;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 300;
    color: #414d55;
    line-height: 36px;
    padding: 0 10px;
    cursor: pointer;
    height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    i {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      margin-top: -2px;
    }

    span {
      display: inline-block;
      vertical-align: top;
      width: 70%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .fliter-collapse {
    background-color: #ffffff;
    border: 1px solid #f0f3f7;
    border-radius: 0px 0px 4px 4px;
    padding: 10px 10px 0;
    display: none;
    position: absolute;
    top: 32px;
    width: 100%;
    left: 0;
    z-index: 10;

    &.active {
      display: block;
    }

    ul {
      display: block;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: block;
        padding-bottom: 5px;

        a {
          color: #414d55;
          display: block;

          &:hover {
            color: #3b7cff;
          }

          p {
            display: inline-block;
            vertical-align: top;
            margin-bottom: 0;
            margin-top: 3px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
          }

          span {
            display: inline-block;
            vertical-align: top;
            text-align: center;
            width: 24px;
            height: 24px;
            line-height: 24px;
            margin-right: 5px;

            img {
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  .fliters-collapse-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    display: none;

    &.active {
      display: block;
    }
  }
}

.source-content {
  .input-group-text {
    width: 100%;
    height: 38px;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: left;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 5px 10px;
  }

  .description-content {
    label {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      display: block;
      margin-bottom: 10px;
      color: #3b4859;

      span {
        font-weight: 400;
        font-style: italic;
      }

      i {
        margin-left: 8px;
      }
    }
  }
}

//*********************************Filter group*************************************//

.filter-control-group {
  width: 160px;
  max-width: 160px;
  padding-left: 0;
  display: inline-block;
  position: relative;
  margin-right: 15px;
  margin-left: 15px;

  label {
    color: #3b4859;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.date-time-range-picker {
    width: 370px;
    max-width: 370px;
  }

  .form-control {
    background-color: #f0f3f7 !important;
    border: none !important;
    font-size: 12px;
    cursor: pointer;
    height: 35px;
  }
}

//*******************************gsl editor logos************************************//
.gsl-editor-logos {
  padding-bottom: 20px;

  h3 {
    display: block;
    font-size: 16px;
    color: #3b4859;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
  }

  ul {
    li {
      display: inline-block;
      vertical-align: top;
      padding-right: 30px;
      padding-bottom: 15px;

      a {
        display: block;
        width: 180px;
        height: 50px;
        background-color: #f0f3f7;
        box-shadow: 0px 1px 5px #00000029;
        border-radius: 5px;
        text-align: left;
        padding-right: 10px;

        span {
          display: inline-block;
          vertical-align: top;
          padding-right: 10px;
          padding-top: 0;
          padding-left: 10px;
          width: 65px;
          line-height: 48px;

          img {
            max-width: 100%;
            height: auto;
          }
        }

        p {
          color: #3b4859;
          font-size: 16px;
          font-weight: 500;
          line-height: 50px;
          display: inline-block;
          vertical-align: top;
        }
      }
    }
  }
}

//****************************************gsl editor radio****************************************//
.gsl-editor-radio {
  margin-bottom: 25px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0 25px 0 0;
      display: inline-block;

      input {
        margin-top: 3px;
        cursor: pointer;
        vertical-align: top;
      }

      label {
        padding-left: 7px;
        vertical-align: top;
        color: #3b4859;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
    }
  }
}

.context-preview-box {
  h4 {
    color: #3b4859;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  .context-preview {
    background-color: #f0f3f7;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 2px;
    padding: 20px 30px;

    .preview {
      padding: 20px 15px 0 15px;

      ul {
        padding: 0;
        margin: 0;
        display: block;
        list-style: none;

        li {
          position: relative;
          padding: 0 0 0 10px;
          margin: 0;
          display: block;
          font-size: 12px;
          line-height: 22px;
          font-weight: 400;
          color: #3b4859;

          i {
            position: absolute;
            left: 0;
            top: 5px;
          }

          a {
            color: #2a9ce7;
          }

          span {
            color: #73954f;
          }
        }
      }
    }
  }
}

//****************************************Form-control****************************************//

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*********************************** Script Search *********************************/

.script-search {
  display: inline-block;
  width: 60.8%;
  margin-right: 10px;

  .input-group-text {
    background-color: #ffffff;
    border: 1px solid #8392a7;
    text-align: left;
    width: 100%;
    font-size: 12px;
    font-weight: 300;
    color: #2662f0;
  }
}

.script-search-btn {
  display: inline-block;
  width: auto;
  margin-right: 10px;

  .alert-white-button {
    background-color: #dde4e9;
    color: #8392a7;
    min-width: auto;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: #3b7cff;
      color: #ffffff;
    }
  }
}

.script-select {
  display: inline-block;
  width: 220px;

  select {
    width: 100%;
    background-color: #efefef;
    border: none;
    color: #414d55;
    font-size: 14px;
    line-height: 36px;
    font-weight: 300;
    border-radius: 4px;
    padding: 2px 10px;
    height: 36px;
  }
}

@media (max-width: 992px) {
  .script-search {
    width: 100%;
    padding-bottom: 15px;
  }

  .script-select {
    width: 40%;
  }
}

//*********************************** Script Editor Code *********************************//
.validate-bottom-text {
  display: block;
  font-size: 11px;
  line-height: 16px;
  color: #3b4859;
  font-weight: 300;
  margin-top: 25px;
}

//*********************************** Open Create Menu *********************************//
.open-create-menu {
  position: absolute;
  z-index: 100;
  right: 15px;
  top: 36px;
  background-color: #fff;
  box-shadow: 0px 2px 5px #00000029;
  width: 120px;
  z-index: 10;
  padding: 5px 0;

  a {
    color: #2a9ce7;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    display: block;
    padding: 4px 0;
  }
}

//******************************************Search- top Head*******************************************//
.search-top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

//********************************************Page header********************************************//
.page-header-container {
  background-color: #e9f4fc;
  padding: 10px 0px;
  padding-left: 10px;

  .page-header {
    color: #7a7a7a;
    margin-right: 30px;
    font-size: 19px;
    font-weight: bold;
    padding: 0px !important;
  }

  .header-button {
    margin-right: 30px;
    color: #7a7a7a !important;
    border: none;
    background: none;

    i {
      padding-right: 10px;
    }

    &:hover {
      color: #040404 !important;
    }
  }
}

/*************************** Wizard ********************/
.wizard-step-line-container {
  padding: 5px;
  background-color: #f8f9fb;

  .wizard-step-button {
    display: inline-block;
    width: 19.9%;
    border-top: 1px solid #707070b5;
    border-bottom: 1px solid #707070b5;
    background-color: #dde4e9;
    font-size: 12px;
    line-height: 20px;
    padding: 5px 0;
    text-align: center;
    color: #414d55;
    font-weight: 500;
    position: relative;

    &:last-child {
      border-right: 1px solid #707070b5;
    }

    &:first-child {
      border-left: 1px solid #707070b5;
    }

    &.active {
      background-color: #ffffff;
      color: #2662f0;
    }

    &:after {
      position: absolute;
      top: 1px;
      right: -27px;
      content: "";
      border: solid transparent;
      border-color: rgba(221, 228, 233, 0);
      border-left-color: #dde4e9;
      border-width: 14px;
      margin-top: 0px;
      width: 0;
      height: 0;
      z-index: 1;
    }

    &:before {
      position: absolute;
      top: 1px;
      right: -28px;
      content: "";
      border: solid transparent;
      border-color: rgba(159, 163, 165, 0);
      border-left-color: #9fa3a5;
      border-width: 14px;
      margin-top: 0px;
      width: 0;
      height: 0;
      z-index: 1;
    }

    &.active:after {
      border-color: rgba(255, 255, 255, 0);
      border-left-color: #ffffff;
    }

    &.active:before {
      border-color: rgba(255, 255, 255, 0);
      border-left-color: #ffffff;
    }

    &:last-child {

      &:after,
      &:before {
        display: none;
      }
    }
  }
}

.wizard-step-component {
  padding: 20px 10px;
  display: block;

  &.d-none {
    display: none;
  }
}

@media (min-width: 1200px) {
  .col-xl-4 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-xl-7 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}

//***************************************unit tabs***************************************//
.urganisational-unit-container {
  .unit-tabs {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #d9e4f2;
    text-align: center;
    margin: 10px 10px 0 10px;
    overflow: hidden;

    ul {
      display: flex;
      padding-left: 2px;
      padding-right: 2px;
      padding-top: 2px;
      padding-bottom: 1px;
      justify-content: flex-start;

      li {
        list-style: none;
        display: contents;

        a {
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          color: #414d55;
          padding: 10px 20px;

          &:hover {
            background-color: #ffffff;
            color: #2662f0;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
          }
        }

        &.active {
          a {
            background-color: #ffffff;
            color: #2662f0;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .urganisational-unit-container {
    .unit-tabs {
      ul {
        li {
          a {
            padding: 10px 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .common-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .search-top-header {
    display: block;

    .header-right {
      text-align: left;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .urganisational-unit-container {
    .unit-tabs {
      ul {
        display: block;
        padding-left: 2px;
        padding-right: 2px;

        li {
          display: block;

          a {
            display: block;
          }

          .active {
            background-color: #ffffff;
            color: #2662f0;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
          }

          &:hover {
            background-color: #ffffff;
            color: #2662f0;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
          }
        }
      }
    }
  }

  .wizard-step-line-container {
    .wizard-step-button {
      width: 100%;
      border-left: 1px solid #707070b5;
      border-right: 1px solid #707070b5;
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid #707070b5;
      }

      &:before,
      &:after {
        display: none;
      }
    }
  }
}

@media (max-width: 540px) {
  .filter-control-group {
    width: 100%;
    max-width: 100%;
  }

}

@media (max-width: 578px) {
  .gsl-editor-logos {
    ul {
      li {
        display: block;
        padding-right: 0;

        a {
          width: 100%;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}