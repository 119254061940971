.head-top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .add-filters {
    display: flex;
    .filter-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #1b4dff;
      padding: 4px 15px;
      margin-right: 15px;
      label {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: #383874;
      }
      span {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: #383874;
      }
      .close-btn {
        padding: 2px;
        border-radius: 50%;
        border: 0.5px solid #000000;
        // .css-1vooibu-MuiSvgIcon-root{
        //   width: 0.7em;
        //   height: 0.7em;
        // }
      }
    }
  }
  .clear-filter-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid red;
    padding: 4px 15px;
    label {
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      color: #383874;
      margin-right: 15px;
    }
    .delete-btn {
      color: red;
    }
  }
  .fliter-button {
    position: relative;
    .primary-outline-btn{
      background: transparent;
    }
    .fliter-toggel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      background: #dde1f8;
      color: #383874;
      font-size: 12px;
      font-weight: 600;
      height: 36px;
      padding: 0 15px;
      border-radius: 4px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  
      .arrow-icon {
        margin-left: 20px;
      }
    }
  
    .fliter-collapse {
      background-color: #ffffff;
      border-radius: 5px;
      padding: 3px;
      display: none;
      position: absolute;
      top: 38px;
      width: 100%;
      right: 0;
      z-index: 10;
      overflow: hidden;
      overflow-y: auto;
      max-height: 200px;
      box-shadow: 0px 4px 32px 0px #00103d29;
  
      &.active {
        display: block;
      }
  
      > ul {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;
  
        li {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0;
          margin-bottom: 0;
          color: #383874;
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          padding: 7px 10px;
          border-radius: 3px;
          cursor: pointer;
  
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
  
          &:hover {
            color: #383874;
            background-color: #dde1f8;
          }
  
          &.active {
            color: #383874;
            background-color: #dde1f8;
          }
  
          i {
            margin-right: 7px;
          }
        }
      }
      > div {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;
  
        .menuItem {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0;
          margin-bottom: 0;
          color: #383874;
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          padding: 7px 10px;
          border-radius: 3px;
          cursor: pointer;
  
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
  
          &:hover {
            color: #383874;
            background-color: #dde1f8;
          }
  
          &.active {
            color: #383874;
            background-color: #dde1f8;
          }
  
          i {
            margin-right: 7px;
          }
        }
      }
    }
  
    .fliters-collapse-bg {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      display: none;
  
      &.active {
        display: block;
      }
    }
  }
}
