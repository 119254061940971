.tiersoc-boxs {
    display: -webkit-box;
    width: 100%;
    // margin-top: 25px;
    overflow: hidden;
    overflow-x: auto;

    .tiersoc-box {
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0px 100px 80px 0px #6c49ac12;
      // width: calc(20.2222% - 15px);
      width: 236px;
      margin-right: 15px;
      margin-bottom: 15px;
      &:last-child {
        margin-right: 0;
      }

      // &:nth-child(5n + 5) {
      //   margin-right: 0;
      // }

      .heading {
        display: flex;
        width: 100%;
        padding: 8px 10px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ececec;
        h3 {
          display: inline-flex;
          align-items: center;
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          color: #383874;
          margin-bottom: 0;

          p {
            display: inline-flex;
            width: 16px;
            height: 16px;
            padding: 3px;
            margin: 0;
            margin-right: 5px;
            border-radius: 50%;
            background-color: #f6f7fb;

            img {
              max-width: 100%;
              height: auto;
            }
          }
          span {
            width: 110px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        a {
          padding: 3px 5px;
          font-size: 11px;
          font-weight: 600;
          color: #384cff;
          text-transform: none;
          background-color: transparent;
        }
        .Mui-disabled {
          color: rgba(0, 0, 0, 0.26);
        }
       
      }

      .contents {
        display: flex;
        width: 100%;
        padding: 10px 15px;
        flex-wrap: wrap;
        min-height: 175px;
        align-items: flex-start;

        .tier-buttons {
          display: flex;
          width: 100%;
          justify-content: center;
          margin-bottom: 10px;
          button {
            padding: 5px 10px;
            font-size: 12px;
            line-height: 18px;
            color: #383874;
            font-weight: 600;
            border-radius: 0;
            border-bottom: 1px solid #c6cbd9;
            text-transform: none;

            &.active {
              border-bottom: 3px solid #8676ff;
            }
          }
        }

        .tier-contents {
          display: flex;
          width: 100%;
          ul {
            list-style: none;
            width: 100%;
            display: block;
            li {
              display: flex;
              font-size: 12px;
              line-height: 20px;
              font-weight: 500;
              color: #383874;
              margin-bottom: 8px;

              &:nth-child(1) {
                span {
                  background-color: #fafbf6;
                }
              }

              &:nth-child(2) {
                span {
                  background-color: #fbf6f6;
                }
              }

              &:nth-child(3) {
                span {
                  background-color: #f6fbf8;
                }
              }

              &:nth-child(4) {
                span {
                  background-color: #f6fbf8;
                }
              }

              span {
                display: inline-flex;
                vertical-align: top;
                width: 20px;
                height: 20px;
                padding: 5px;
                text-align: center;
                border-radius: 50%;
                margin-right: 5px;

                img {
                  vertical-align: middle;
                }
              }
              label {
                display: inline-flex;
              }
              strong {
                font-size: 14px;
                font-weight: 600;
                margin-left: auto;
              }
            }
          }
        }
      }

      .buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;

        .button {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 7px;
          padding-bottom: 7px;
          font-size: 12px;
          line-height: 16px;
          p {
            width: 80px;
            margin: 0;
            font-size: 12px;
            font-weight: 600;
            font-family: "Open Sans", sans-serif;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        a {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 3px;
          padding-bottom: 3px;
          font-size: 12px;
        }
      }
    }
  }